import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class MediaService {
  constructor(private http: HttpClient) { }

  uploadImage(data: any) {
      return this.http.post(`${environment.urlApi}/media/upload/`, data)
        .pipe(
          map( url => url )
        );
  }

  uploadURN(data: any) {
    return this.http.post(`${environment.urlApi}/media/upload/constructive-detail`, data)
      .pipe(
        map( url => url )
      );
  }

  getHomeIcons() {
    return this.http.get(`${environment.urlApi}/home-icons`);
  }
}