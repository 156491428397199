import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {PaginatorInterface} from '../interfaces/paginator.interface';
import { Article } from 'app/models';
import { map } from 'rxjs/operators';

@Injectable()
export class ArticleService {

  constructor(
      private http: HttpClient
  ) { }

  findArticles(infoPaginate: PaginatorInterface) {
      let objFilter = {
        'page': infoPaginate.page.toString(),
        'limit': infoPaginate.limit.toString(),
        'filter[text][regex]': infoPaginate.text || '',
      }
      if(infoPaginate.category) objFilter['filter[category][in]'] = infoPaginate.category;
      if(infoPaginate.msi) objFilter['msi'] = infoPaginate.msi;
      const params = new HttpParams({fromObject: objFilter});
      return this.http.get<any>(`${environment.urlApi}/content/find/article`, {params: params});
  }

  findArticlesBySubtype(infoPaginate: PaginatorInterface, subtype: string) {
    let objFilter = {
      'page': infoPaginate.page.toString(),
      'limit': infoPaginate.limit.toString(),
    }
    if(infoPaginate.calendarDate) objFilter['filter[calendarDate][gte]'] = infoPaginate.calendarDate;
    if(infoPaginate.msi) objFilter['msi'] = infoPaginate.msi;
    const params = new HttpParams({fromObject: objFilter});
    return this.http.get<any>(`${environment.urlApi}/content/find/article/${subtype}`, {params: params});
  }

  getFeatured() {
    return this.http.get(`${environment.urlApi}/content/article/featured`);
  }

  getTypes() {
    return this.http.get(`${environment.urlApi}/content/article/types`);
  }

  addArticle(data: Article) {
    return this.http.post(`${environment.urlApi}/content/article/`, data)
      .pipe(
        map( result => result)
      );
  }

  updateArticle(data: Article, id: string) {
    return this.http.patch(`${environment.urlApi}/content/article/${id}`, data)
      .pipe(
        map( result => result )
      );
  }

  deleteArticle(id: string) {
    return this.http.delete(`${environment.urlApi}/content/article/${id}`);
  }

  getMinisites(filter: string) {
    return this.http.get(`${environment.urlApi}/content/article/minisites?filter=${filter}`);
  }
}
