import { Component, OnInit, ViewChild, Input, OnChanges } from '@angular/core';
import { DragScrollComponent } from 'ngx-drag-scroll/lib/ngx-drag-scroll';

@Component({
  selector: 'app-scroll-content',
  templateUrl: './scroll-content.component.html',
  styleUrls: ['./scroll-content.component.scss']
})
export class ScrollContentComponent implements OnInit {
  @Input() content: any[] = [];

  @ViewChild('nav', {read: DragScrollComponent}) ds: DragScrollComponent;
  leftNavDisabled = false;
  rightNavDisabled = false;

  constructor() { }

  ngOnInit() {

  }

  moveLeft() {
    this.ds.moveLeft();
  }
 
  moveRight() {
    this.ds.moveRight();
  }

  leftBoundStat(reachesLeftBound: boolean) {
    this.leftNavDisabled = reachesLeftBound;
  }

  rightBoundStat(reachesRightBound: boolean) {
    this.rightNavDisabled = reachesRightBound;
  }

}
