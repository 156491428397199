import * as fromArticleActions from '../actions/article.actions';
import { Article } from 'app/models';

export interface ArticleState {
  category: string;
  search: string;
  page: number;
  articles: Article[];
}

export const initialState: ArticleState = {
  category: '',
  search: '',
  page: 1,
  articles: []
}


export type ArticleActions = fromArticleActions.SearchAction;

export function ArticleReducer(state = initialState, action: ArticleActions): ArticleState {
  switch (action.type) {
    case fromArticleActions.SEARCH_ARTICLES: {
      return {
        category: action.payload.category ? action.payload.category : '',
        search: action.payload.search ? action.payload.search : '',
        page: action.payload.page,
        articles: action.payload.articles
      }
    }
    default: {
      return state;
    }
  }
}
