import * as fromProjectActions from '../actions/project.actions';
import { Project } from 'app/models';

export interface ProjectState {
  category: string;
  search: string;
  typology: string;
  page: number;
  projects: Project[];
}

export const initialState: ProjectState = {
  category: '',
  typology: '',
  search: '',
  page: 1,
  projects: []
}


export type ProjectActions = fromProjectActions.ProjectAction;

export function ProjectReducer(state = initialState, action: ProjectActions): ProjectState {
  switch (action.type) {
    case fromProjectActions.SEARCH_PROJECT: {
      return {
        category: action.payload.category ? action.payload.category : '',
        typology: action.payload.typology ? action.payload.typology : '',
        search: action.payload.search ? action.payload.search : '',
        page: action.payload.page,
        projects: action.payload.projects
      }
    }
    default: {
      return state;
    }
  }
}
