import {Category} from '../category';
import {Attributes} from '../attributes';
import {MLItem} from '../mlitem';
import {Media} from '../media';
import {Link} from '../link';
import { Project } from './project';
import {Minisite} from "../minisite";

export class ConstructiveDetail {
    id: string;
    title: MLItem;
    author: string;
    authorLink: string;
    contributor: string;
    slug: string;
    category: Category[];
    datePublish: string;
    project: string | Project;
    calendarDate: Date;
    description: MLItem;
    descriptionSubscribers: MLItem;
    linkRelatedArticles: Link[];
    linkRelatedConstructionDetails: Link[];
    linkRelatedProjects: Link[];
    images: Media[];
    complementaryFields: Attributes[];
    headerImage: Media;
    technicalDrawings: Media;
    documents: Media[];
    location: string;
    year: string;
    collaborators: string;
    opened: boolean;
    draft: boolean;
    published: boolean;
    createdByTectonica: boolean;
    keywords: string[];
    urn: string;
    featured: boolean;
    photographer: string;
    photographerLink: string;
    bookmark: boolean;
    viewerVisibility: boolean;
    minisites: string[];
}
