import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { AuthenticationService } from '../../services/authentication.service';
import * as authActions from '../actions/authentication.actions';
import { switchMap, map, catchError, tap } from "rxjs/operators";
import { of } from "rxjs";
import { Router } from "@angular/router";
import {environment} from "../../../environments/environment";
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class AuthenticationEffects {
  constructor(
    private actions$: Actions,
    private authenticationService: AuthenticationService,
    private router: Router,
    private cookieService: CookieService,
  ) {}

  @Effect()
  login$ = this.actions$.pipe(
      ofType(authActions.LOGIN),
      map( (action: authActions.LoginAction) => action.payload),
      switchMap( (payload) => {
        return this.authenticationService.login(payload.username, payload.password).pipe(
          map(user => new authActions.LoginSuccessAction(user)),
          catchError( error => of(new authActions.LoginFailureAction(error)) )
        )
      })
    )

  @Effect({ dispatch: false })
  loginSuccess$ = this.actions$.pipe(
    ofType(authActions.LOGIN_SUCCESS),
    tap( (action: authActions.LoginSuccessAction) => {
      //localStorage.setItem('currentUser', JSON.stringify(action.payload))
      this.cookieService.set('loggedin', JSON.stringify(action.payload), 90, '/', environment.appUrl);
    })
  )

  @Effect({ dispatch: false })
  logout$ = this.actions$.pipe(
    ofType(authActions.LOGOUT),
    tap( () => {
      //localStorage.removeItem('currentUser')
      this.cookieService.delete('loggedin','/',environment.appUrl);
      this.router.navigate(['/login']);
    })
  )
}
