import { Action } from '@ngrx/store';

export const LOGIN = '[Authentication] Login';
export const LOGIN_SUCCESS = '[Authentication] Login Success';
export const LOGIN_FAILURE = '[Authentication] Login Failure';
export const LOGOUT = '[Authentication] Logout';

export class LoginAction implements Action {
  readonly type = LOGIN;

  constructor (public payload: any) {}
}

export class LoginSuccessAction implements Action {
  readonly type = LOGIN_SUCCESS;

  constructor (public payload: any) {}
}

export class LoginFailureAction implements Action {
  readonly type = LOGIN_FAILURE;

  constructor (public payload: any) {}
}

export class LogoutAction implements Action {
  readonly type = LOGOUT;
}
