import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { ClipboardModule } from 'ngx-clipboard';

import { AppComponent } from './app.component';

import { CookieService } from 'ngx-cookie-service';
import { AuthenticationService } from './services/authentication.service';
import { UserService } from './services/user.service';
import {TranslateModule, TranslateLoader, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { AuthGuard } from './services/auth.guard';
import { DomainAppGuard } from './services/domainApp.guard';
import { DomainAdminGuard } from './services/domainAdmin.guard';
import { ArticleService } from './services/article.service';
import { ToastrModule } from 'ngx-toastr';
import { RedirectComponent } from './components/redirect/redirect.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { RedirectGuard } from './services/redirectGuard.guard';
import { CollectiveGuard } from './services/collective.guard';

import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { appReducers } from './store/app.state';
import { environment } from 'environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { effectsArr } from './store/effects';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
    declarations: [
      AppComponent,
      RedirectComponent,
      PageNotFoundComponent,
    ],
    imports: [
      BrowserAnimationsModule,
      StoreModule.forRoot(appReducers),
      !environment.production ? StoreDevtoolsModule.instrument({maxAge: 25, logOnly: environment.production}) : [],
      EffectsModule.forRoot(effectsArr),
      ToastrModule.forRoot(),
      AppRoutingModule,
      SharedModule,
      HttpClientModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
        }
      }),
      NgbModule.forRoot(),
      ClipboardModule
    ],
    providers: [
      CookieService,
      AuthenticationService,
      UserService,
      ArticleService,
      AuthGuard,
      DomainAppGuard,
      DomainAdminGuard,
      RedirectGuard,
      CollectiveGuard,
      { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private translate: TranslateService) {
    translate.addLangs(['es', 'en']);
    translate.setDefaultLang('es');

    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/es|en/) ? browserLang : 'es');
  }
}
