export const SUBSCRIBER_ROLES = [
  'ROLE_SUB',
  'ROLE_SUB_STUDENT',
  'ROLE_MANUFACTURER',
  'ROLE_MANUFACTURER_PREMIUM',
  'ROLE_MEDIA_PARTNER',
  'ROLE_EDITOR',
  'ROLE_ADMIN',
];

export class User {
  id: string;
  email: string;
	plainPassword: string;
	firstName: string;
  lastName: string;
  businessName: string;
  cif: string;
  province: string;
  country: string;
	phone: string;
  avatar: string;
  sector: string;
  currentPassword: string;
  repeatPassword: string;
	professionalActivity: string;
  billingInformation: any;
  roles: any[];
  newsletter: boolean;
  lastLogin: string;
  comments: any[];
  enabled: boolean;
  ipAddressString: any[];
  collective: boolean;
  origin: string;
}
