import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ContentService } from 'app/services/entity.service';
import { first } from 'rxjs/operators';
import { AdService } from 'app/services/ad.service';

@Component({
  selector: 'app-content-card',
  templateUrl: './content-card.component.html',
  styleUrls: ['./content-card.component.scss']
})
export class ContentCardComponent implements OnInit {

  @Input() createdByTectonica: boolean = false;
  @Input() id: string
  @Input() bookmark: boolean;
  @Input() opened: boolean = false;
  @Input() type: string
  @Input() slug: string
  @Input() image: string
  @Input() url: string
  @Input() line1: string
  @Input() line2: string
  @Input() line3: string
  @Input() line4: string

  constructor(
    private router: Router,
    private contentService: ContentService,
    private adService: AdService
  ) {}

  ngOnInit() {

  }

  goToContent() {
    if(this.type === 'project') this.router.navigate(['/projects', this.slug])
    else if(this.type === 'article') this.router.navigate(['/articles', this.slug])
    else if(this.type === 'constructive-detail') this.router.navigate(['/constructive-details', this.slug])
    else if(this.type === 'material') this.router.navigate(['/materials', this.slug])
    else if(this.type === 'manufacturer') this.router.navigate(['/manufacturers', this.slug]) 
  }

  toggleBookmark() {
    if(this.bookmark) {
      this.contentService.deleteBookmark(this.id)
        .pipe(first())
        .subscribe( result => {
          this.bookmark = !this.bookmark;
        })
    } else {
      
      const data = {
        content: this.id
      }
      
      this.contentService.addBookmark(data)
        .pipe(first())
        .subscribe (result => {
        this.bookmark = !this.bookmark;
      })
    }
    
  }

  openAdvertising() {
    this.adService.adIncrementClick(this.id)
      .pipe(first())
      .subscribe( result => {

      })
  }

}
