import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { RouterModule } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DragScrollModule } from 'ngx-drag-scroll';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { TreeModule } from 'angular-tree-component';
import { ChartsModule } from 'ng2-charts';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatStepperModule, MatTreeModule, MatAutocompleteModule, MatCheckboxModule } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

import { CategoryService } from 'app/services/category.service';
import { UserService } from 'app/services/user.service';
import { PaginatorComponent } from './paginator/paginator.component';
import { NGXToastrService } from 'app/services/ngx.toastr.service';
import { ProfessionalActivitiesService } from 'app/services/professionalActivities.service';
import { ProvinceService } from 'app/services/province.service';
import { CountryService } from 'app/services/country.service';
import { SectorService } from 'app/services/sector.service';
import { ContentService } from 'app/services/entity.service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ManufacturerService } from 'app/services/manufacturer.service';
import { ContactService } from 'app/services/contact.service';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { FileUploadModule } from 'ng2-file-upload';
import { QuillModule } from 'ngx-quill';
import { ShareButtonsModule } from 'ngx-sharebuttons';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';

import { FooterComponent } from "./footer/footer.component";
import { MenuComponent } from './menu/menu.component';
import { HomescheduleComponent } from 'app/components/home/homeschedule/homeschedule.component';
import { PartnersComponent } from 'app/components/home/partners/partners.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { ContentHeaderGalleryComponent } from './content-header-gallery/content-header-gallery.component';
import { ContentCardComponent } from './content-card/content-card.component';
import { DetailsEntityBreadcrumbsComponent } from './details-entity-breadcrumbs/details-entity-breadcrumbs.component';
import { ScrollContentComponent } from './scroll-content/scroll-content.component';
import { ChartsComponent } from './charts/charts.component';
import { HeaderContactManufacturerComponent } from './header-contact-manufacturer/header-contact-manufacturer.component';
import { PublicationsTechnicalArticleComponent } from 'app/components/account/publications-technical-article/publications-technical-article.component';
import { PublicationsTechnicalArticleOkComponent } from 'app/components/account/publications-technical-article-ok/publications-technical-article-ok.component';
import { PublicationsMaterialComponent } from 'app/components/account/publications-material/publications-material.component';
import { PublicationsMaterialOkComponent } from 'app/components/account/publications-material-ok/publications-material-ok.component';
import { PublicationsConstructiveDetailComponent } from 'app/components/account/publications-constructive-detail/publications-constructive-detail.component';
import { PublicationsConstructiveDetailOkComponent } from 'app/components/account/publications-constructive-detail-ok/publications-constructive-detail-ok.component';
import { PublicationsScheduleArticleComponent } from 'app/components/account/publications-schedule-article/publications-schedule-article.component';
import { PublicationsScheduleArticleOkComponent } from 'app/components/account/publications-schedule-article-ok/publications-schedule-article-ok.component';
import { AdService } from 'app/services/ad.service';
import { PublicProfileComponent } from 'app/components/account/public-profile/public-profile.component';
import { PublicProfileOkComponent } from 'app/components/account/public-profile-ok/public-profile-ok.component';
import { LayoutModule } from '@angular/cdk/layout';
import { SlugifyPipe } from 'app/pipes/slugify.pipe';
import { UploadedMediaPipe } from 'app/pipes/uploaded-media.pipe';
import localeEs from '@angular/common/locales/es';
import { AdminUserCreateComponent } from '../components/admin/admin-user-create/admin-user-create.component';
import { MatCardModule } from '@angular/material/card';
import { SubscriptionAlertComponent } from './subscription-alert/subscription-alert.component';

registerLocaleData(localeEs, 'es-ES');

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MM YYYY',
  },
};

@NgModule({
  exports: [
    CommonModule,
    NgbModule,
    MenuComponent,
    FooterComponent,
    HomescheduleComponent,
    PartnersComponent,
    SidebarComponent,
    ContentHeaderGalleryComponent,
    ContentCardComponent,
    DragScrollModule,
    DetailsEntityBreadcrumbsComponent,
    ScrollContentComponent,
    PaginatorComponent,
    ChartsComponent,
    HeaderContactManufacturerComponent,
    PublicationsTechnicalArticleComponent,
    PublicationsTechnicalArticleOkComponent,
    PublicationsMaterialComponent,
    PublicationsMaterialOkComponent,
    PublicationsConstructiveDetailComponent,
    PublicationsConstructiveDetailOkComponent,
    PublicationsScheduleArticleComponent,
    PublicationsScheduleArticleOkComponent,
    PublicProfileComponent,
    PublicProfileOkComponent,
    AdminUserCreateComponent,
    SlugifyPipe,
    UploadedMediaPipe,
    SubscriptionAlertComponent
  ],
  imports: [
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    NgbModule,
    MatDatepickerModule,
    MatIconModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    MatCheckboxModule,
    DragScrollModule,
    MatSidenavModule,
    MatSelectModule,
    MatTooltipModule,
    MatButtonModule,
    MatStepperModule,
    QuillModule,
    MatTreeModule,
    MatAutocompleteModule,
    ChartsModule,
    FileUploadModule,
    LayoutModule,
    ScrollToModule.forRoot(),
    ShareButtonsModule.forRoot(),
    TreeModule.forRoot(),
    TranslateModule.forChild(),
    NgxMaterialTimepickerModule.forRoot(),
    MatCardModule,
  ],
  declarations: [
    MenuComponent,
    FooterComponent,
    HomescheduleComponent,
    PartnersComponent,
    SidebarComponent,
    ContentHeaderGalleryComponent,
    ContentCardComponent,
    DetailsEntityBreadcrumbsComponent,
    ScrollContentComponent,
    PaginatorComponent,
    ChartsComponent,
    HeaderContactManufacturerComponent,
    PublicationsTechnicalArticleComponent,
    PublicationsTechnicalArticleOkComponent,
    PublicationsMaterialComponent,
    PublicationsMaterialOkComponent,
    PublicationsConstructiveDetailComponent,
    PublicationsConstructiveDetailOkComponent,
    PublicationsScheduleArticleComponent,
    PublicationsScheduleArticleOkComponent,
    PublicProfileComponent,
    PublicProfileOkComponent,
    AdminUserCreateComponent,
    SlugifyPipe,
    UploadedMediaPipe,
    SubscriptionAlertComponent
  ],
  providers: [
    CategoryService,
    UserService,
    ProvinceService,
    ProfessionalActivitiesService,
    CountryService,
    NGXToastrService,
    SectorService,
    ContentService,
    ManufacturerService,
    ContactService,
    AdService,
    SlugifyPipe,
    UploadedMediaPipe,
    { provide: MAT_DATE_LOCALE, useValue: 'es' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: LOCALE_ID, useValue: 'es-ES' }
  ]
})
export class SharedModule {}
