import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { AuthGuard } from './services/auth.guard';
import { DomainAppGuard } from './services/domainApp.guard';
import { DomainAdminGuard } from './services/domainAdmin.guard';
import { RedirectComponent } from './components/redirect/redirect.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { CollectiveGuard } from './services/collective.guard';

const appRoutes: Routes = [
  { path: '', loadChildren: './components/home/home.module#HomeModule', pathMatch: 'full', },
  { path: '', loadChildren: './components/login/login.module#LoginModule' },
  { path: '', loadChildren: './components/contents/contents.module#ContentModule', canActivate: [DomainAppGuard] },
  { path: 'subscription', loadChildren: './components/subscription/subscription.module#SubscriptionModule', canActivate: [DomainAppGuard] },
  { path: 'articles', loadChildren: './components/articles/articles.module#ArticleModule' },
  { path: 'projects', loadChildren: './components/projects/projects.module#ProjectModule' },
  { path: 'materials', loadChildren: './components/materials/materials.module#MaterialsModule' },
  { path: 'constructive-details', loadChildren: './components/constructive-details/constructive-details.module#ConstructiveDetailModule' },
  { path: 'manufacturers', loadChildren: './components/manufacturers/manufacturers.module#ManufacturerModule', canActivate: [DomainAppGuard] },
  { path: 'account', loadChildren: './components/account/account.module#AccountModule', canActivate: [AuthGuard, DomainAppGuard, CollectiveGuard] },
  { path: 'admin', loadChildren: './components/admin/admin.module#AdminModule', data: { showMenu: false }, canActivate: [AuthGuard, DomainAdminGuard] },
  { path: 'search', loadChildren: './components/search/search.module#SearchModule', canActivate: [DomainAppGuard] },
  { path: 'category', loadChildren: './components/search/search.module#SearchModule', canActivate: [DomainAppGuard] },
  { path: 'redirecting', component: RedirectComponent },
  { path: 'page-not-found', component: PageNotFoundComponent },
  { path: '**', redirectTo: 'page-not-found',  pathMatch: 'full', },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
