import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-publications-schedule-article-ok',
  templateUrl: './publications-schedule-article-ok.component.html',
  styleUrls: ['./publications-schedule-article-ok.component.scss']
})
export class PublicationsScheduleArticleOkComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
