import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpParams, HttpHeaders} from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ConstructiveDetail } from 'app/models';
import { PaginatorInterface } from 'app/interfaces/paginator.interface';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class ConstructiveDetailService {

  constructor(
      private http: HttpClient,
      private cookieService: CookieService
  ) { }

  findConstructiveDetails(infoPaginate: PaginatorInterface) {
    let objFilter = {
      'page': infoPaginate.page.toString(),
      'limit': infoPaginate.limit.toString(),
      'filter[text][regex]': infoPaginate.text || '',
    }
    if(infoPaginate.category) objFilter['filter[category][in]'] = infoPaginate.category;
    if(infoPaginate.msi) objFilter['msi'] = infoPaginate.msi;
    const params = new HttpParams({fromObject: objFilter});

    return this.http.get<any>(`${environment.urlApi}/content/find/constructiveDetail`, {params: params, headers: this.getHeaders()});
  }


  addConstructiveDetail(data: ConstructiveDetail) {
      return this.http.post(`${environment.urlApi}/content/constructiveDetail/`, data)
        .pipe(
          map( result => result)
        );
  }

  updateConstructiveDetail(data: ConstructiveDetail, id: string) {
    return this.http.patch(`${environment.urlApi}/content/constructiveDetail/${id}`, data)
      .pipe(
        map( result => result )
      );
  }

  deleteConstructiveDetail(id: string) {
    return this.http.delete(`${environment.urlApi}/content/constructiveDetail/${id}`);
  }

  getFeatured() {
    return this.http.get(`${environment.urlApi}/content/constructiveDetail/featured`, {headers: this.getHeaders()});
  }

  getHeaders(){
    let headers = {
      'Cache-Control':  'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
      'Pragma': 'no-cache',
      'Expires': '0'
    };

    //let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    let userCookie = null;
    if(this.cookieService.check('loggedin')){
      userCookie = this.cookieService.get('loggedin');
    }
    let currentUser = JSON.parse(userCookie);
    if (currentUser && currentUser.token) {
      headers['Authorization'] = `Bearer ${currentUser.token}`;
    }

    return headers;
  }

  getMinisites(filter: string) {
    return this.http.get(`${environment.urlApi}/content/constructiveDetail/minisites?filter=${filter}`);
  }
}
