import { Component, OnInit, ViewChild, Output, EventEmitter, Input, OnChanges, OnDestroy } from '@angular/core';

import { CategoryService } from 'app/services/category.service';
import { first } from 'rxjs/operators';
import { ITreeOptions, TREE_ACTIONS } from 'angular-tree-component';
import { NGXToastrService } from 'app/services/ngx.toastr.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store/app.state';
import { DrawerAction } from 'app/store/actions/main.actions';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnChanges, OnDestroy {
  @Output() private searchOnEntity = new EventEmitter();
  @Output() private categoryOnEntity = new EventEmitter();
  @Input() category: string = '';

  @ViewChild('drawer') drawer;
  @ViewChild('tree') tree;

  search: string = "";
  breadcrumbs: any[] = [];
  isMobile: boolean = false;
  sub: Subscription = new Subscription();

  showLateralFixed: boolean = true;
  categories: any = [];
  optionsTree: ITreeOptions = {
    actionMapping: {
      mouse: {
        click: (tree, node, $event) => {
          node.setIsActive(true);
          this.category = '';

          if(node.isActive) this.category = node.data.id;
          this.emitCategoryField();

          if(!node.isLeaf) TREE_ACTIONS.TOGGLE_EXPANDED(tree, node, $event);
        },
        expanderClick: (tree, node, $event) => {
          if (node.isRoot && node.isCollapsed) tree.collapseAll();

          TREE_ACTIONS.TOGGLE_EXPANDED(tree, node, $event);
        }
      }
    },
  };

  constructor(
    private categoryService: CategoryService,
    private ngxToastrService: NGXToastrService,
    public breakpointObserver: BreakpointObserver,
    private store: Store<AppState>,
  ) {

  }

  ngOnChanges() {
    if (this.category === '' && this.tree.treeModel.getActiveNode()) {
      this.returnIndex()
      this.tree.treeModel.getActiveNode().toggleActivated()
      this.tree.treeModel.collapseAll();
    }
  }

  ngOnInit() {
    this.getCategories('parents');

    this.breakpointObserver
      .observe(['(max-width: 575.98px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isMobile = true;
        }
      });

    this.sub = this.store.select('main').subscribe( state => {
      if (state.drawerOpen) {
        this.drawer.toggle();
        this.toggleSidebar();
      }
    })
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  closingDrawer() {
    const actionPayload = {
      drawerOpen: false
    }
    this.store.dispatch( new DrawerAction(actionPayload) );
  }

  onTreeLoad() {
    if (this.category) {
      const someNode = this.tree.treeModel.getNodeById(this.category);

      if (someNode) {
        someNode.setIsActive(true);
        someNode.expand();

        if (someNode.parent) {
          this.toggleParent(someNode.parent)
        }
      }
    }
  }

  toggleParent(parent, action = 'expand') {
    if (action === 'expand') {
      parent.expand();
    } else {
      parent.collapse();
    }

    if (parent.parent) {
      this.toggleParent(parent.parent, action)
    }
  }

  toggleSidebar() {
    this.showLateralFixed = !this.showLateralFixed;
  }

  getCategories(filter) {
    this.categoryService.getCategories(filter)
      .pipe(first())
      .subscribe( (data: any) => {
        if (Array.isArray(data)) {
          this.categories = data;
        } else {
          this.categories = [data];
        }
        this.breadcrumbs = [];
        if (filter !== 'parents') {
          this.getBreadcrumbs(filter)

          if (this.tree.treeModel.getActiveNode()) {
            const someNode = this.tree.treeModel.getNodeById(this.tree.treeModel.getActiveNode().data.id);
            someNode.setIsActive(false);
            someNode.collapse();

            if (someNode.parent) {
              this.toggleParent(someNode.parent, 'colapse')
            }
          }
        } else if (this.category) {
          this.getBreadcrumbs(this.category);
        }
      },
      error => {
        this.ngxToastrService.typeError('Error en la consulta!', 'Se ha producido un error al obtener los datos de categorías')
      });
  }

  getBreadcrumbs(id) {
    if(id) {
      this.categoryService.getBreadcrumbs(id).subscribe(
        (response: any) => {
          if(response.id) {
            this.checkChidrenBreadcrumbs(response)
          }
        },
        errorResponse => {
            this.ngxToastrService.typeError('Error en la consulta!', 'Se ha producido un error al obtener los datos de breadcrumbs')
        });
    }
  }

  checkChidrenBreadcrumbs(data) {
    this.breadcrumbs.push({'id': data.id, 'name': data.name});

    if (data.children) {
      this.checkChidrenBreadcrumbs(data.children[0])
    }
  }

  emitSearchField() {
    if (this.isMobile) {
      this.drawer.toggle();
    }

    this.searchOnEntity.emit(this.search);
  }

  emitCategoryField() {
    if (this.isMobile) {
      this.drawer.toggle();
    }

    this.categoryOnEntity.emit(this.category);

    this.getCategories(this.category);
  }

  returnIndex() {
    this.category = '';
    this.getCategories('parents')

    this.categoryOnEntity.emit(this.category);
  }

  filterCatergories() {
    this.categoryService.search(this.search)
      .pipe(first())
      .subscribe( (data: any) => {
        if (Array.isArray(data)) {
          this.categories = data;
        } else {
          this.categories = [data];
        }
      },
      error => {
        this.ngxToastrService.typeError('Error en la consulta!', 'Se ha producido un error al obtener los datos de categorías')
      });
  }
}
