import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subscription-alert',
  templateUrl: './subscription-alert.component.html',
  styleUrls: ['./subscription-alert.component.scss']
})
export class SubscriptionAlertComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
