import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class ProvinceService {
    constructor(private http: HttpClient) { }

    getProvinces(filter: string) {
      let stringFilter = '';
      if(filter) {
        stringFilter = `?filter[country][eq]=${filter}`
      }
      return this.http.get(`${environment.urlApi}/province/${stringFilter}`);
    }

}
