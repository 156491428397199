import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class CountryService {
    constructor(private http: HttpClient) { }

    getCountries() {
        return this.http.get(`${environment.urlApi}/country/`);
    }

}
