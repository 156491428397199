import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Router, ActivationStart } from '@angular/router';
import { filter, first } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { AuthenticationService } from 'app/services/authentication.service';
import { UserService } from 'app/services/user.service';
import { NGXToastrService } from 'app/services/ngx.toastr.service';
import { environment } from '../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})

export class MenuComponent implements OnInit, OnChanges {
  @Input() scrollTop: boolean;
  showMenu: boolean = true;
  currentUrl: string = '';
  navbarBgWhite: boolean = true;
  isNavbarCollapsed: boolean = true;

  sub: Subscription = new Subscription();
  subProfile: Subscription = new Subscription();
  subAdminProfile: Subscription = new Subscription();

  isLoggedIn: boolean = false;
  isAdminLoggedIn: boolean = false;
  notShowAdminDomain: boolean = false;
  user: any = {};
  freeUser: boolean = false;

  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private userService: UserService,
    private ngxToastrService: NGXToastrService,
    private cookieService: CookieService
  ) {
    this.sub = router.events.pipe( filter(event => event instanceof ActivationStart) )
      .subscribe(event => {
        window.scrollTo(0, 0);

        this.isNavbarCollapsed = true;
        if (event['snapshot'].data.showMenu !== undefined) {
          this.showMenu = event['snapshot'].data.showMenu;
        } else this.showMenu = true;

        if (event['snapshot']._routerState) this.currentUrl = event['snapshot']._routerState.url;

        this.checkBgMenu();
      });
  }

  ngOnInit() {
    this.subProfile = this.authService.isLoggedIn.subscribe( value => {
      this.isLoggedIn = value;

      if(this.isLoggedIn) {
        //let userLocalStorage = JSON.parse(localStorage.getItem('currentUser'));
        let userCookie = null;
        if(this.cookieService.check('loggedin')){
          userCookie = this.cookieService.get('loggedin');
        }
        let userLocalStorage = JSON.parse(userCookie);

        if(userLocalStorage) this.getUserData(userLocalStorage.email);

        this.userService.getUser(userLocalStorage.email)
          .pipe(first())
          .subscribe(
            (data: any) => {
              this.user = data;

              // SI NO TIENE NINGUN ROL ES USUARIO FREE
              if (!this.user.roles.includes('ROLE_SUB') &&
                !this.user.roles.includes('ROLE_SUB_STUDENT') &&
                !this.user.roles.includes('ROLE_MANUFACTURER') &&
                !this.user.roles.includes('ROLE_MANUFACTURER_PREMIUM') &&
                !this.user.roles.includes('ROLE_MEDIA_PARTNER') &&
                !this.user.roles.includes('ROLE_ADMIN') &&
                !this.user.roles.includes('ROLE_EDITOR')) {
                this.freeUser = true;
              }

            },
            error => {
              this.ngxToastrService.typeError('Error en la consulta!', 'Se ha producido un error al obtener los datos del usuario.')
            });
      }
    })

    this.subAdminProfile = this.authService.isAdmin.subscribe( value => {
      this.isAdminLoggedIn = value;

      if(value || environment.adminUrl === document.location.host) {
        this.notShowAdminDomain = true;
      } else {
        this.notShowAdminDomain = false;
      }

      if(this.isAdminLoggedIn) {
        //let userLocalStorage = JSON.parse(localStorage.getItem('currentUser'));
        let userCookie = null;
        if(this.cookieService.check('loggedin')){
          userCookie = this.cookieService.get('loggedin');
        }
        let userLocalStorage = JSON.parse(userCookie);

        if(userLocalStorage) this.getUserData(userLocalStorage.email);
      }
    })
  }

  ngOnChanges() {
    this.checkBgMenu();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    this.subProfile.unsubscribe();
    this.subAdminProfile.unsubscribe();
  }

  getUserData(email) {
    this.userService.getUser(email)
      .pipe(first())
      .subscribe(
        (data: any) => {
          this.user = data;
        },
        error => {
          this.ngxToastrService.typeError('Error en la consulta!', 'Se ha producido un error al obtener los datos de usuario')
        });
  }

  checkBgMenu() {
    (this.currentUrl === '/' && this.scrollTop) ? this.navbarBgWhite = false : this.navbarBgWhite = true;
  }

  toggleCollapsed() {
    this.isNavbarCollapsed = !this.isNavbarCollapsed;
  }
}
