import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-content-header-gallery',
  templateUrl: './content-header-gallery.component.html',
  styleUrls: ['./content-header-gallery.component.scss']
})
export class ContentHeaderGalleryComponent implements OnInit {

  @Input() featured: any[] = [];
  

  constructor(
    private router: Router,
  ) {}

  ngOnInit() {
  }

  goToContent(content) {
    if(content.type === 'project') this.router.navigate(['/projects', content.slug])
    else if(content.type === 'article') this.router.navigate(['/articles', content.slug])
    else if(content.type === 'constructiveDetail') this.router.navigate(['/constructive-details', content.slug])
    else if(content.type === 'material') this.router.navigate(['/materials', content.slug])
    else if(content.type === 'manufacturer') this.router.navigate(['/manufacturers', content.slug]) 
  }

}
