import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthenticationService } from 'app/services/authentication.service';
import {Store} from "@ngrx/store";
import {AppState} from "../store/app.state";
import {LogoutAction} from "../store/actions/authentication.actions";


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService,
                private store: Store<AppState>) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
          if (err.status === 401 || err.status === 403) {
              // auto logout if 401 response returned from api
              this.authenticationService.logout();
              this.store.dispatch( new LogoutAction() );
              //location.reload(true);
          }

          return throwError(err);
        }))
    }
}
