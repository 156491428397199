import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(
      private cookieService: CookieService
    ){}
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        //let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        let userCookie = null;
        if(this.cookieService.check('loggedin')){
          userCookie = this.cookieService.get('loggedin');
        }
        let currentUser = JSON.parse(userCookie);

        if (currentUser && currentUser.token) {
            request = request.clone({
                setHeaders: {
                    'Authorization': `Bearer ${currentUser.token}`,
                    'Cache-Control':  'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
                    'Pragma': 'no-cache',
                    'Expires': '0'
                }
            });
        } else {
          request = request.clone({
            setHeaders: {
                'Cache-Control':  'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
                'Pragma': 'no-cache',
                'Expires': '0'
            }
          });
        }

        return next.handle(request);
    }
}
