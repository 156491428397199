import { Injectable } from '@angular/core';
import {ToastrService} from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class NGXToastrService {

  constructor(public toastrService: ToastrService) { }

    typeError(title: string, message: string) {
        this.toastrService.error(message, title, {timeOut: 7000});
    }

    typeSuccess(title: string, message: string) {
      this.toastrService.success(message, title, {timeOut: 7000});
    }
}
