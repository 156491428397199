import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from './services/authentication.service';
import { filter, first, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from './store/app.state';
import { LoginSuccessAction } from './store/actions/authentication.actions';
import { NavigationEnd, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import {environment} from "../environments/environment";

declare var gtag;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  scrollTop: boolean = true;

  constructor(
    private authService: AuthenticationService,
    private store: Store<AppState>,
    private router: Router,
    private cookieService: CookieService
  ) {
    //const userLocalStorage = JSON.parse(localStorage.getItem('currentUser'));
    let userCookie = null;
    if(this.cookieService.check('loggedin')){
      userCookie = this.cookieService.get('loggedin');
    }
    const userLocalStorage = JSON.parse(userCookie);

    if (userLocalStorage) {
      this.authService.checkRole(userLocalStorage.email, false);
    } else {
      this.loginByIp();
    }

    const navEndEvent$ = router.events.pipe(
      filter(e => e instanceof NavigationEnd)
    );

    navEndEvent$.subscribe((e: NavigationEnd) => {
      console.log(e)
      gtag('config', 'UA-139349638-1', {
        'page_path': e.urlAfterRedirects
      });
    })
  }

  ngOnInit() {

  }

  loginByIp() {
    this.authService.loginByIp().pipe(take(1)).subscribe(
      user => {
        if (user && user.token) {
          //localStorage.setItem('currentUser', JSON.stringify(user));
          this.cookieService.set('loggedin', JSON.stringify(user), 90, '/', environment.appUrl);
          this.authService.checkRole(user.email, true);
          this.authService.loggedIn.next(true);
          this.store.dispatch(new LoginSuccessAction(user));
        }
      }
    );
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    window.pageYOffset > 0 ? this.scrollTop = false : this.scrollTop = true;
  }
}
