import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class SectorService {
    constructor(private http: HttpClient) { }

    getSectors() {
        return this.http.get(`${environment.urlApi}/sector`);
    }

}