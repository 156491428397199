import * as fromManufacturerActions from '../actions/manufacturer.actions';
import { Manufacturer } from 'app/models';

export interface ManufacturerState {
  category: string;
  search: string;
  page: number;
  manufacturers: Manufacturer[];
}

export const initialState: ManufacturerState = {
  category: '',
  search: '',
  page: 1,
  manufacturers: []
}


export type ManufacturerActions = fromManufacturerActions.ManufacturerAction;

export function ManufacturerReducer(state = initialState, action: ManufacturerActions): ManufacturerState {
  switch (action.type) {
    case fromManufacturerActions.SEARCH_MANUFACTURER: {
      return {
        category: action.payload.category ? action.payload.category : '',
        search: action.payload.search ? action.payload.search : '',
        page: action.payload.page,
        manufacturers: action.payload.manufacturers
      }
    }
    default: {
      return state;
    }
  }
}
