import * as fromDetailActions from '../actions/detail.actions';
import { ConstructiveDetail } from 'app/models';

export interface DetailState {
  category: string;
  search: string;
  page: number;
  details: ConstructiveDetail[];
}

export const initialState: DetailState = {
  category: '',
  search: '',
  page: 1,
  details: []
}


export type DetailActions = fromDetailActions.DetailAction;

export function DetailReducer(state = initialState, action: DetailActions): DetailState {
  switch (action.type) {
    case fromDetailActions.SEARCH_DETAILS: {
      return {
        category: action.payload.category ? action.payload.category : '',
        search: action.payload.search ? action.payload.search : '',
        page: action.payload.page,
        details: action.payload.details
      }
    }
    default: {
      return state;
    }
  }
}
