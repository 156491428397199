import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import { map } from 'rxjs/operators';

import { Ad } from 'app/models/ad';
import { PaginatorInterface } from 'app/interfaces/paginator.interface';

@Injectable()
export class AdService {

  constructor(
      private http: HttpClient
  ) { }

  findAds(infoPaginate: PaginatorInterface) {
    let objFilter = {
      'page': infoPaginate.page.toString(),
      'limit': infoPaginate.limit.toString(),
      'filter[text][regex]': infoPaginate.text || '',
    }

    const params = new HttpParams({fromObject: objFilter});
    return this.http.get<any>(`${environment.urlApi}/ad/`, {params: params});
  }

  getAd(id: string) {
    return this.http.get(`${environment.urlApi}/ad/${id}`);
  }

  addAd(data: Ad) {
    return this.http.post(`${environment.urlApi}/ad/`, data)
      .pipe(
        map( result => result)
      );
  }

  updateAd(data: Ad, id: string) {
    return this.http.patch(`${environment.urlApi}/ad/${id}`, data)
      .pipe(
        map( result => result )
      );
  }

  deleteAd(id: string) {
    return this.http.delete(`${environment.urlApi}/ad/${id}`);
  }

  getContentAd() {
    return this.http.get(`${environment.urlApi}/ad/bytype/?filter[type][A]=1&filter[type][B]=1&filter[type][C]=1`);
  }

  adIncrementClick(idAd: string) {
    return this.http.post(`${environment.urlApi}/ad/${idAd}`, {})
      .pipe(
        map( result => result)
      );
  }
}
