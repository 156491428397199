import { Component, HostBinding, OnInit, OnDestroy } from '@angular/core';
import { AuthenticationService } from 'app/services/authentication.service';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})

export class FooterComponent implements OnInit, OnDestroy {
  subAdminProfile: Subscription = new Subscription();
  notShowAdminDomain: boolean = false;

  constructor (
    private authService: AuthenticationService,
  ) {}
  
  ngOnInit() {
    this.subAdminProfile = this.authService.isAdmin.subscribe( value => {
      if(value || environment.adminUrl === document.location.host) {
        this.notShowAdminDomain = true;
      } else {
        this.notShowAdminDomain = false;
      }
    })
  }

  ngOnDestroy() {
    this.subAdminProfile.unsubscribe();
  }
}
