import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-publications-constructive-detail-ok',
  templateUrl: './publications-constructive-detail-ok.component.html',
  styleUrls: ['./publications-constructive-detail-ok.component.scss']
})
export class PublicationsConstructiveDetailOkComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
