import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {FileUploader, FileItem, FileUploaderOptions} from 'ng2-file-upload/ng2-file-upload';
import { first, debounceTime, switchMap } from 'rxjs/operators';
import {
  Country,
  Media,
  Category,
  Attributes,
  Material,
  Link,
  ConstructiveDetail,
  Article,
  Project,
  Manufacturer,
  Minisite
} from 'app/models';
import { CountryService } from 'app/services/country.service';
import { CategoryService } from 'app/services/category.service';
import { NGXToastrService } from 'app/services/ngx.toastr.service';
import { SlugifyPipe } from 'app/pipes/slugify.pipe';
import { MediaService } from 'app/services/media.service';
import { ElementTypeService } from 'app/services/elementTypes.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MaterialService } from 'app/services/material.service';
import { MLItem } from 'app/models/mlitem';
import swal from 'sweetalert2';
import { ContentService } from 'app/services/entity.service';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material';
import { Observable, Subscription } from 'rxjs';
import { ConstructiveDetailService } from 'app/services/constructiveDetail.service';
import { ArticleService } from 'app/services/article.service';
import { ProjectService } from 'app/services/project.service';
import { PaginatorInterface } from 'app/interfaces/paginator.interface';

import * as QuillNamespace from 'quill';
import ImageResize from 'quill-image-resize-module';
import { QuillEditorComponent } from 'ngx-quill/src/quill-editor.component';
import { AuthenticationService } from 'app/services/authentication.service';
import { ClipboardService } from 'ngx-clipboard';
import {ManufacturerService} from '../../../services/manufacturer.service';
import * as moment from 'moment';

const maxSize: number = 4 * 1024 * 1024;
const maxTotalSize: number = 20 * 1024 * 1024;

const imgFileUploaderOptionsForUser: FileUploaderOptions = {
  url: '',
  allowedMimeType: ['image/png', 'image/jpg', 'image/jpeg', 'image/gif'],
  isHTML5: true,
  maxFileSize: maxSize
};

const imgFileUploaderOptionsForAdmin: FileUploaderOptions = {
  url: '',
  allowedMimeType: ['image/png', 'image/jpg', 'image/jpeg', 'image/gif'],
  isHTML5: true
};

const documentsUploaderOptionsForUser: FileUploaderOptions = {
  url: '',
  isHTML5: true,
  maxFileSize: maxSize
};

const documentsUploaderOptionsForAdmin: FileUploaderOptions = {
  url: '',
  isHTML5: true
};

@Component({
  selector: 'app-publications-material',
  templateUrl: './publications-material.component.html',
  styleUrls: ['./publications-material.component.scss']
})
export class PublicationsMaterialComponent implements OnInit, OnDestroy {
  saving: boolean = false;
  isAdmin: boolean = false;
  validating: boolean = false;
  materialFormGroup: FormGroup;
  data: Material = new Material();
  isLinear: boolean = false;
  company: any = new Manufacturer();
  isEdit: boolean = false;

  sub: Subscription = new Subscription();

  @ViewChild('editor') editor: QuillEditorComponent;
  editor_modules: any = {};
  quillEditorRefEs: any;
  quillEditorRefEn: any;
  quillEditorSubsRefEs: any;
  quillEditorSubsRefEn: any;

  uploaderHeaderImg: FileUploader;

  uploaderSequenceImg: FileUploader;

  documentsFiles: Media[] = [];
  urlDocument: string = "";
  uploaderDocuments: FileUploader;

  hasBaseDropZoneOver = false;
  hasAnotherDropZoneOver = false;

  sequenceFiles: Media[] = [];
  videos: Media[] = [];
  distributors: Attributes[] = [];
  areas: any[] = []
  categories: Category[] = [];
  minisites: Minisite[] = [];

  video_platforms: any[] = [];
  doc_types: any[] = [];

  selectedCategories: string[] = [];
  nestedTreeControl: NestedTreeControl<any>;
  nestedDataSource: MatTreeNestedDataSource<any>;

  selectedMinisites: string[] = [];
  nestedTreeControlMinisites: NestedTreeControl<any>;
  nestedDataSourceMinisites: MatTreeNestedDataSource<any>;

  linkRelatedConstructionDetails: Link[] = [];
  linkRelatedArticles: Link[] = [];
  linkRelatedProjects: Link[] = [];
  filteredConstructiveDetails: Observable<ConstructiveDetail>;
  filteredArticles: Observable<Article>;
  filteredProjects: Observable<Project>;
  filteredManufacturers: Observable<Manufacturer>;

  constructor(
    private _location: Location,
    private formBuilder: FormBuilder,
    private countryService: CountryService,
    private categoryService: CategoryService,
    private ngxToastrService: NGXToastrService,
    private slugifyPipe: SlugifyPipe,
    private mediaService: MediaService,
    private elementTypeService: ElementTypeService,
    private router: Router,
    private materialService: MaterialService,
    private activatedRoute: ActivatedRoute,
    private contentService: ContentService,
    private constructiveDetailService: ConstructiveDetailService,
    private articleService: ArticleService,
    private projectService: ProjectService,
    private manufacturerService: ManufacturerService,
    private authService: AuthenticationService,
    private clipboardService: ClipboardService
  ) {
    this.nestedTreeControl = new NestedTreeControl<any>(this._getChildren);
    this.nestedTreeControlMinisites = new NestedTreeControl<any>(this._getChildrenMinisites);
    this.nestedDataSource = new MatTreeNestedDataSource();
    this.nestedDataSourceMinisites = new MatTreeNestedDataSource();
  }

  hasNestedChild = (_: number, nodeData: any) => nodeData.children;
  hasNestedChildMinisites = (_: number, nodeDataMinisites: any) => nodeDataMinisites.children;

  private _getChildren = (node: any) => node.children;
  private _getChildrenMinisites = (nodeMinisites: any) => nodeMinisites.children;

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  ngOnInit() {
    this.getAreas();
    this.getVideoPlatforms();
    this.getCategories();
    this.getDocType();
    this.getNestedCategories('parents');
    this.getMinisites();
    this.getNestedMinisites('parents');
    this.quillEditorConfig();

    this.sub = this.authService.admin.subscribe(isAdmin => this.isAdmin = isAdmin)
    const now = moment();

    if(this.isAdmin) {

      this.materialFormGroup = this.formBuilder.group({
        material: ['', Validators.required],
        material_en: [''],
        content: ['', Validators.required],
        content_en: [''],
        contentSubscribers: [''],
        contentSubscribers_en: [''],
        keywords: ['', Validators.required],
        blocks: [[], Validators.required],
        privacy: [false, Validators.required],
        draft: [true],
        company: [''],
        date_publish: [this.dateFormat(new Date(), '-'), Validators.required],
        hour_publish: [now.hour(), [Validators.required, Validators.pattern('[01]?[0-9]|2[0-3]')]],
        linkRelatedConstructionDetails: [''],
        linkRelatedArticles: [''],
        linkRelatedProjects: [''],
        createdByTectonica: [false, Validators.required],
        manufacturerVisibility: [false, Validators.required],
        minisites: [this.selectedMinisites]
      });

    }else{

      this.materialFormGroup = this.formBuilder.group({
        material: ['', Validators.required],
        material_en: [''],
        content: ['', Validators.required],
        content_en: [''],
        contentSubscribers: [''],
        contentSubscribers_en: [''],
        keywords: ['', Validators.required],
        blocks: [[], Validators.required],
        privacy: [false, Validators.required],
        draft: [true],
        company: [''],
        date_publish: [this.dateFormat(new Date(), '-'), Validators.required],
        hour_publish: [now.hour(), [Validators.required, Validators.pattern('[01]?[0-9]|2[0-3]')]],
        linkRelatedConstructionDetails: [''],
        linkRelatedArticles: [''],
        linkRelatedProjects: ['']
      });

    }

    if (this.isAdmin) {
      this.uploaderHeaderImg = new FileUploader(imgFileUploaderOptionsForAdmin);
      this.uploaderSequenceImg = new FileUploader(imgFileUploaderOptionsForAdmin);
      this.uploaderDocuments = new FileUploader(documentsUploaderOptionsForAdmin);
    } else {
      this.uploaderHeaderImg = new FileUploader(imgFileUploaderOptionsForUser);
      this.uploaderSequenceImg = new FileUploader(imgFileUploaderOptionsForUser);
      this.uploaderDocuments = new FileUploader(documentsUploaderOptionsForUser);
    }

    this.uploaderSequenceImg.onWhenAddingFileFailed = (fileItem) => {
      this.ngxToastrService.typeError(`Error al subir el archivo ${fileItem.name}`, 'No se ha podido añadir un archivo porque su tamaño es mayor del permitido.')
    };

    this.uploaderDocuments.onWhenAddingFileFailed = (fileItem) => {
      this.ngxToastrService.typeError(`Error al subir el archivo ${fileItem.name}`, 'No se ha podido añadir un archivo porque su tamaño es mayor del permitido.')
    };

    let slug = this.activatedRoute.snapshot.paramMap.get('slug');

    if(slug) {

      this.isEdit = true;

      let allowMinisites = 0;

      if(this.isAdmin) {
        allowMinisites = 1;
      }

      this.contentService.getContent(slug, allowMinisites)
        .pipe(first())
        .subscribe(
          (data: Material) => {
            this.data = data;

            for(const cat of data.category) {
              this.selectedCategories.push(cat.id);
            }

            if(this.isAdmin && data.minisites){
              for(const mst of data.minisites) {
                if(mst) { // @ts-ignore
                  this.selectedMinisites.push(mst);
                }
              }
            }

            let keywords = "";
            if(data.keywords)
            {
              for(const key of data.keywords) {
                if(key) keywords += `${key},`;
              }
            }

            const publishedDate = moment(data.datePublish);

            let descriptionSubscribersES: string = '';
            let descriptionSubscribersEN: string = '';
            if(data.descriptionSubscribers) {
              if(data.descriptionSubscribers.es) descriptionSubscribersES = data.descriptionSubscribers.es
              if(data.descriptionSubscribers.en) descriptionSubscribersEN = data.descriptionSubscribers.en
            }

            if(this.isAdmin) {

              this.materialFormGroup = this.formBuilder.group({
                material: [data.title.es, Validators.required],
                material_en: [data.title.en],
                content: [data.description.es, Validators.required],
                content_en: [data.description.en],
                contentSubscribers: [descriptionSubscribersES],
                contentSubscribers_en: [descriptionSubscribersEN],
                keywords: [keywords, Validators.required],
                blocks: [this.selectedCategories, Validators.required],
                privacy: [true, Validators.required],
                draft: [data.draft],
                company: [''],
                date_publish: [data.datePublish, Validators.required],
                hour_publish: [publishedDate.hour(), [Validators.required, Validators.pattern('[01]?[0-9]|2[0-3]')]],
                linkRelatedConstructionDetails: [''],
                linkRelatedArticles: [''],
                linkRelatedProjects: [''],
                createdByTectonica: [data.createdByTectonica, Validators.required],
                manufacturerVisibility: [data.manufacturerVisibility, Validators.required],
                minisites: [this.selectedMinisites],
              });

            }else{

              this.materialFormGroup = this.formBuilder.group({
                material: [data.title.es, Validators.required],
                material_en: [data.title.en],
                content: [data.description.es, Validators.required],
                content_en: [data.description.en],
                contentSubscribers: [descriptionSubscribersES],
                contentSubscribers_en: [descriptionSubscribersEN],
                keywords: [keywords, Validators.required],
                blocks: [this.selectedCategories, Validators.required],
                privacy: [true, Validators.required],
                draft: [data.draft],
                company: [''],
                date_publish: [data.datePublish, Validators.required],
                hour_publish: [publishedDate.hour(), [Validators.required, Validators.pattern('[01]?[0-9]|2[0-3]')]],
                linkRelatedConstructionDetails: [''],
                linkRelatedArticles: [''],
                linkRelatedProjects: ['']
              });

            }

            this.sequenceFiles = data.images;
            this.documentsFiles = data.documents;
            this.videos = data.video;
            this.distributors = data.dealers;

            this.company = <Manufacturer>data.company || '';
            this.linkRelatedConstructionDetails = data.linkRelatedConstructionDetails || [];
            this.linkRelatedArticles = data.linkRelatedArticles || [];
            this.linkRelatedProjects = data.linkRelatedProjects || [];

            this.loadAutocompletes();
          },
          error => {
            this.router.navigate(['/page-not-found'])
            this.ngxToastrService.typeError('Error en la consulta!', 'Se ha producido un error al obtener los datos del contenido')
          });
    } else {
      this.loadAutocompletes();
    }
  }

  quillEditorConfig() {
    let Quill: any = QuillNamespace;
    const BlockEmbed = Quill.import("blots/block/embed");

    class VideoBlot extends BlockEmbed {
      static create(url) {
        let node = super.create(url);
        let iframe = document.createElement('iframe');
        // Set styles for wrapper
        node.setAttribute('class', 'embed-responsive embed-responsive-16by9');
        // Set styles for iframe
        iframe.setAttribute('frameborder', '0');
        iframe.setAttribute('allowfullscreen', 'true');
        iframe.setAttribute('src', url);
        // Append iframe as child to wrapper
        node.appendChild(iframe);
        return node;
      }

      static value(domNode) {
        return domNode.firstChild.getAttribute('src');
      }
    }
    VideoBlot.blotName = 'video';
    VideoBlot.tagName = 'div';

    Quill.register(VideoBlot, true);
    Quill.register('modules/imageResize', ImageResize);

    this.editor_modules = {
      toolbar: {
        container: [
          [{ 'font': [] }],
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          [{ 'size': ['small', false, 'large', 'huge'] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{ 'color': [] }, { 'background': [] }],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          [{ 'align': [] }],
          ['clean'], // remove formatting button
          ['link', 'image', 'video']
        ]
      },
      imageResize: true
    };
  }

  getEditorInstance(editorInstance: any, type: string) {
    if (type === 'es') {
      this.quillEditorRefEs = editorInstance;
    } else if(type === 'en') {
      this.quillEditorRefEn = editorInstance;
    } else if(type === 'sub_es') {
      this.quillEditorSubsRefEs = editorInstance;
    } else {
      this.quillEditorSubsRefEn = editorInstance;
    }

    const toolbar = editorInstance.getModule('toolbar');
    toolbar.addHandler('image', this.imageHandler);
  }

  imageHandler = (image, callback) => {
    const input = <HTMLInputElement> document.getElementById('fileInputField');
    document.getElementById('fileInputField').onchange = () => {
      let file: File;
      file = input.files[0];

      if (!this.isAdmin && file.size > maxSize) {
        this.ngxToastrService.typeError(`Error al subir la imagen ${file.name}`, 'No se puede subir la imagen porque excede el tamaño máximo (4 MB)');
        input.value = '';
        return;
      }

      // file type is only image.
      if (/^image\//.test(file.type)) {
        const reader  = new FileReader();
        reader.onload = async () =>  {
          const fileData = new FormData();
          fileData.append('media', file, this.slugifyPipe.transform(file.name))
          fileData.append('type', file.type.split('/')[1]);
          fileData.append('name', this.slugifyPipe.transform(file.name));

          let s3Url: string;

          await this.mediaService.uploadImage(fileData)
            .toPromise().then( (url) => {
              s3Url = url.toString();

              const rangeEs = this.quillEditorRefEs.getSelection();
              const rangeEn = this.quillEditorRefEn.getSelection();
              const rangeSubsEs = this.quillEditorSubsRefEs.getSelection();
              const rangeSubsEn = this.quillEditorSubsRefEn.getSelection();
              const img = '<img src="' + s3Url + '" />';
              if (rangeEs !== null) {
                this.quillEditorRefEs.clipboard.dangerouslyPasteHTML(rangeEs.index, img);
              } else if (rangeEn !== null) {
                this.quillEditorRefEn.clipboard.dangerouslyPasteHTML(rangeEn.index, img);
              } else if (rangeSubsEs !== null) {
                this.quillEditorSubsRefEs.clipboard.dangerouslyPasteHTML(rangeSubsEs.index, img);
              } else if (rangeSubsEn !== null) {
                this.quillEditorSubsRefEn.clipboard.dangerouslyPasteHTML(rangeSubsEn.index, img);
              }
            });
        };
        reader.readAsBinaryString(file);
      } else {
        this.ngxToastrService.typeError('Error', 'Solo se pueden subir imágenes en el editor');
      }
    };
    input.click();
  };

  getVideoPlatforms() {
    this.video_platforms = this.elementTypeService.getVideoPlatforms();
  }

  getDocType() {
    this.doc_types = this.elementTypeService.getDocType();
  }

  getAreas() {
    this.areas = this.elementTypeService.getAreas();
  }

  getCategories() {
    this.categoryService.getCategories('')
      .pipe(first())
      .subscribe(
        (data: any) => {
          this.categories = data;
        },
        error => {
          this.ngxToastrService.typeError('Error en la consulta!', 'Se ha producido un error al obtener los datos de categorías')
        });
  }

  onChangeCategory($event) {
    if($event.checked) {
      this.selectedCategories.push($event.source.value)
    } else {
      this.selectedCategories = this.selectedCategories.filter( value => value !== $event.source.value)
    }

    this.materialFormGroup.controls['blocks'].setValue(this.selectedCategories)
  }

  onChangeMinisite($event) {
    if($event.checked) {
      this.selectedMinisites.push($event.source.value)
    } else {
      this.selectedMinisites = this.selectedMinisites.filter( value => value !== $event.source.value)
    }

    this.materialFormGroup.controls['minisites'].setValue(this.selectedMinisites)
  }

  getNestedCategories(filter) {
    this.categoryService.getCategories(filter)
      .pipe(first())
      .subscribe( (data: any) => {
          this.nestedDataSource.data = data;
        },
        error => {
          this.ngxToastrService.typeError('Error en la consulta!', 'Se ha producido un error al obtener los datos de categorías')
        });
  }

  getMinisites() {
    this.materialService.getMinisites('')
      .pipe(first())
      .subscribe(
        (data: any) => {
          this.minisites = data;
        },
        error => {
          this.ngxToastrService.typeError('Error en la consulta!', 'Se ha producido un error al obtener los datos de minisites')
        });
  }

  getNestedMinisites(filter) {
    this.materialService.getMinisites(filter)
      .pipe(first())
      .subscribe( (data: any) => {
          this.nestedDataSourceMinisites.data = data;
        },
        error => {
          this.ngxToastrService.typeError('Error en la consulta!', 'Se ha producido un error al obtener los datos de minisites')
        });
  }

  back() {
    this._location.back();
  }

  loadAutocompletes() {
    let infoPaginateConstructiveDetails: PaginatorInterface = {
      hasNext: false,
      items: 0,
      limit: 200,
      page: 1,
      text: '',
      category: ''
    };

    this.filteredConstructiveDetails = this.materialFormGroup.get('linkRelatedConstructionDetails').valueChanges
      .pipe(
        debounceTime(300),
        switchMap(value => {
          infoPaginateConstructiveDetails.text = value;
          return this.constructiveDetailService.findConstructiveDetails(infoPaginateConstructiveDetails)
        })
      );

    let infoPaginateArticle: PaginatorInterface = {
      hasNext: false,
      items: 0,
      limit: 200,
      page: 1,
      text: '',
      category: ''
    };

    this.filteredArticles = this.materialFormGroup.get('linkRelatedArticles').valueChanges
      .pipe(
        debounceTime(300),
        switchMap(value => {
          infoPaginateArticle.text = value;
          return this.articleService.findArticles(infoPaginateArticle)
        })
      );

    let infoPaginateProject: PaginatorInterface = {
      hasNext: false,
      items: 0,
      limit: 200,
      page: 1,
      text: '',
      category: ''
    };

    this.filteredProjects = this.materialFormGroup.get('linkRelatedProjects').valueChanges
      .pipe(
        debounceTime(300),
        switchMap(value => {
          infoPaginateProject.text = value;
          return this.projectService.findProjects(infoPaginateProject)
        })
      );

    if (this.isAdmin) {

      let infoPaginateManufacturer: PaginatorInterface = {
        hasNext: false,
        items: 0,
        limit: 200,
        page: 1,
        text: '',
        category: ''
      };

      this.filteredManufacturers = this.materialFormGroup.get('company').valueChanges
        .pipe(
          debounceTime(300),
          switchMap(value => {
            infoPaginateManufacturer.text = value;
            return this.manufacturerService.findManufacturers(infoPaginateManufacturer)
          })
        );
    }
  }

  // DROP ZONE
  droppedHeaderImg($files) {
    let item: FileItem = this.uploaderHeaderImg.queue[this.uploaderHeaderImg.queue.length - 1]

    if (item) {
      this.uploaderHeaderImg.clearQueue();
      this.uploaderHeaderImg.queue.push(item);
    } else {
      this.ngxToastrService.typeError('Error al subir el archivo', 'El tamaño del archivo es demasiado grande.')
    }
  }

  onFileSelectedHeaderImg() {
    let item: FileItem = this.uploaderHeaderImg.queue[this.uploaderHeaderImg.queue.length - 1]

    if (item) {
      this.uploaderHeaderImg.clearQueue();
      this.uploaderHeaderImg.queue.push(item);
    } else {
      this.ngxToastrService.typeError('Error al subir el archivo', 'El tamaño del archivo es demasiado grande.')
    }
  }

  getSequenceSize(): number {
    let totalSize = 0;
    if(!this.isAdmin){
      this.sequenceFiles.forEach(value => {
        totalSize += value.file.size;
      });
    }
    return totalSize;
  }

  droppedSequenceImg($files) {
    let totalSize = this.getSequenceSize();

    for (var i = 0; i < $files.length; i++) {
      let file = new Media();
      file.file = $files[i];
      file.name = $files[i].name;

      totalSize += file.file.size;

      if (!this.isAdmin) {
        if (this.sequenceFiles.length <= 12 && totalSize <= maxTotalSize) {
          this.sequenceFiles.push(file);
        } else {
          this.ngxToastrService.typeError(
            `Error al subir el archivo ${file.name}`,
            'El tamaño total es mayor del permitido.'
          )
        }
      } else {
        if (this.sequenceFiles.length <= 12) {
          this.sequenceFiles.push(file);
        }
      }
    }

    this.uploaderSequenceImg.clearQueue();
  }

  onFileSelectedSequenceImg() {
    let totalSize = this.getSequenceSize();

    this.uploaderSequenceImg.queue.forEach(element => {
      let file = new Media();
      file.file = element._file;
      file.name = element._file.name;

      totalSize += element._file.size;

      if (!this.isAdmin) {
        if (this.sequenceFiles.length <= 12 && totalSize <= maxTotalSize) {
          this.sequenceFiles.push(file);
        } else {
          this.ngxToastrService.typeError(
            `Error al subir el archivo ${file.name}`,
            'El tamaño total es mayor del permitido.'
          )
        }
      } else {
        if (this.sequenceFiles.length <= 12) {
          this.sequenceFiles.push(file);
        }
      }
    });

    this.uploaderSequenceImg.clearQueue();
  }

  removeSequenceFile(index) {
    this.sequenceFiles.splice(index, 1);
  }

  addDUrlDocument() {
    let file = new Media();
    file.name = this.urlDocument;
    file.url = this.urlDocument;

    this.documentsFiles.push(file)
    this.urlDocument = "";
  }

  droppedDocuments($files) {
    for (var i = 0; i < $files.length; i++) {
      let file = new Media();
      file.file = $files[i];
      file.name = $files[i].name;

      this.documentsFiles.push(file)
    }

    this.uploaderDocuments.clearQueue();
  }

  onFileSelectedDocuments() {
    this.uploaderDocuments.queue.forEach(element => {
      let file = new Media();
      file.file = element._file;
      file.name = element._file.name;

      this.documentsFiles.push(file)
    })

    this.uploaderDocuments.clearQueue();
  }

  removeDocumentFile(index) {
    this.documentsFiles.splice(index, 1);
  }

  fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }

  // DROP ZONE

  // RELATED CONSTRUCTIVE DETAILS
  displayFnRelatedConstructiveDetail(data: ConstructiveDetail) {
    if (data) { return data.title.es; }
  }

  addRelatedConstructiveDetail() {
    if(this.materialFormGroup.get('linkRelatedConstructionDetails').value.id) {
      let data = new Link();
      data.referenceId = this.materialFormGroup.get('linkRelatedConstructionDetails').value.id;
      data.name = this.materialFormGroup.get('linkRelatedConstructionDetails').value.title.es;
      data.slug = this.materialFormGroup.get('linkRelatedConstructionDetails').value.slug;
      data.type = "constructiveDetail";
      data.url = `/constructive-details/${this.materialFormGroup.get('linkRelatedConstructionDetails').value.slug}`;

      this.linkRelatedConstructionDetails.push(data)

      this.materialFormGroup.get('linkRelatedConstructionDetails').setValue('');
    }
  }

  removeRelatedConstructiveDetail(index) {
    this.linkRelatedConstructionDetails.splice(index, 1);
  }
  // RELATED CONSTRUCTIVE DETAILS
  // RELATED ARTICLES
  displayFnRelatedArticle(data: ConstructiveDetail) {
    if (data) { return data.title.es; }
  }

  addRelatedArticle() {
    if(this.materialFormGroup.get('linkRelatedArticles').value.id) {
      let data = new Link();
      data.referenceId = this.materialFormGroup.get('linkRelatedArticles').value.id;
      data.name = this.materialFormGroup.get('linkRelatedArticles').value.title.es;
      data.slug = this.materialFormGroup.get('linkRelatedArticles').value.slug;
      data.type = "article";
      data.url = `/articles/${this.materialFormGroup.get('linkRelatedArticles').value.slug}`;

      this.linkRelatedArticles.push(data)

      this.materialFormGroup.get('linkRelatedArticles').setValue('');
    }
  }

  removeRelatedArticle(index) {
    this.linkRelatedArticles.splice(index, 1);
  }
  // RELATED ARTICLES
  // RELATED PROJECTS
  displayFnRelatedProject(data: Project) {
    if (data) { return data.title.es; }
  }

  addRelatedProject() {
    if(this.materialFormGroup.get('linkRelatedProjects').value.id) {
      let data = new Link();
      data.referenceId = this.materialFormGroup.get('linkRelatedProjects').value.id;
      data.name = this.materialFormGroup.get('linkRelatedProjects').value.title.es;
      data.slug = this.materialFormGroup.get('linkRelatedProjects').value.slug;
      data.type = "project";
      data.url = `/projects/${this.materialFormGroup.get('linkRelatedProjects').value.slug}`;

      this.linkRelatedProjects.push(data)

      this.materialFormGroup.get('linkRelatedProjects').setValue('');
    }
  }

  removeRelatedProject(index) {
    this.linkRelatedProjects.splice(index, 1);
  }
  // RELATED PROJECTS

  // MANUFACTURER
  displayFnManufacturer(data: Manufacturer) {
    if (data) { return data.title.es; }
  }

  addManufacturer() {
    if (this.materialFormGroup.get('company').value.id) {

      this.company = this.materialFormGroup.get('company').value;
      this.materialFormGroup.get('company').setValue('');
    }
  }

  removeManufacturer() {
    this.company = '';
  }
  // MANUFACTURER

  addDistributor() {
    this.distributors.push(new Attributes())
  }

  removeDistributor(index) {
    this.distributors.splice(index, 1);
  }

  addVideo() {
    this.videos.push(new Media())
  }

  removeVideo(index) {
    this.videos.splice(index, 1);
  }

  cancel() {
    this._location.back();
  }

  dateFormat(date, separator = '/') {
    function twoDigit(n) { return (n < 10 ? '0' : '') + n; }

    return `${date.getFullYear()}${separator}${twoDigit(date.getMonth() + 1)}${separator}${twoDigit(date.getDate())}`;
  }

  copyToClipboard(text: string){
    this.clipboardService.copyFromContent(text);
    this.ngxToastrService.typeSuccess("Copiado", "Url copiada al portapapeles");
  }

  async onSubmit(form: FormGroup)
  {
    this.validating = true;

    if (!this.company.id) {
      this.materialFormGroup.controls['company'].setErrors({'required': true});
      this.validating = false;
    } else {
      this.materialFormGroup.controls['company'].setErrors(null);
    }

    if(form.valid) {
      this.validating = false;
      this.saving = true;

      let data = new Material();

      data.datePublish = this.dateFormat(new Date(form.value.date_publish));
      data.datePublish = `${data.datePublish} ${form.value.hour_publish}:00`;

      let title = new MLItem();
      title.es = form.value.material;
      title.en = form.value.material_en;
      data.title = title;

      let description = new MLItem();
      description.es = form.value.content;
      description.en = form.value.content_en;
      data.description = description;

      let descriptionSubscribers = new MLItem();
      descriptionSubscribers.es = form.value.contentSubscribers;
      descriptionSubscribers.en = form.value.contentSubscribers_en;
      data.descriptionSubscribers = descriptionSubscribers;

      data.category = form.value.blocks;
      data.dealers = this.distributors;
      data.video = this.videos;
      data.draft = form.value.draft;
      data.keywords = form.value.keywords.split(',');
      data.linkRelatedConstructionDetails = this.linkRelatedConstructionDetails;
      data.linkRelatedArticles = this.linkRelatedArticles;
      data.linkRelatedProjects = this.linkRelatedProjects;
      if (this.company.id && this.isAdmin) {
        data.company = this.company.id;
      }
      if(this.isAdmin && this.isEdit){
        data.createdByTectonica = form.value.createdByTectonica;
      }
      if(this.isAdmin){
        data.manufacturerVisibility = form.value.manufacturerVisibility;
        data.minisites = this.materialFormGroup.controls['minisites'].value;
      }

      if(this.uploaderHeaderImg.queue.length > 0) {
        let headerImage = new Media();
        let imageData = new FormData();

        imageData.append('media', this.uploaderHeaderImg.queue[0]._file, this.slugifyPipe.transform(this.uploaderHeaderImg.queue[0]._file.name))
        imageData.append('type', this.uploaderHeaderImg.queue[0]._file.type.split('/')[1]);
        imageData.append('name', this.slugifyPipe.transform(this.uploaderHeaderImg.queue[0]._file.name));

        await this.mediaService.uploadImage(imageData)
          .toPromise().then( (url) => {
            headerImage.url = url.toString();
            headerImage.type = this.uploaderHeaderImg.queue[0]._file.type.split('/')[1];
            headerImage.name = this.slugifyPipe.transform(this.uploaderHeaderImg.queue[0]._file.name);
          });

        data.headerImage = headerImage;
      }

      let documents: Media[] = [];

      for( const file of this.documentsFiles ) {
        if(file.file) {
          let fileData = new FormData();
          fileData.append('media', file.file, this.slugifyPipe.transform(file.file.name))
          if(file.file.name.substr(file.file.name.lastIndexOf('.') + 1).toLowerCase() == 'dwg'){
            fileData.append('type', 'dwg');
          }else{
            fileData.append('type', file.file.type.split('/')[1]);
          }
          fileData.append('name', this.slugifyPipe.transform(file.file.name));
          fileData.append('subscribersOnly', file.file.subscribersOnly);

          await this.mediaService.uploadImage(fileData)
            .toPromise().then( (url) => {
              file.url = url.toString();
              delete file.file;
            })
        }

        documents.push(file);

      }

      data.documents = documents

      let images: Media[] = [];

      for( const file of this.sequenceFiles ) {
        if(file.file) {
          let fileData = new FormData();
          fileData.append('media', file.file, this.slugifyPipe.transform(file.file.name))
          fileData.append('type', file.file.type.split('/')[1]);
          fileData.append('name', this.slugifyPipe.transform(file.file.name));

          await this.mediaService.uploadImage(fileData)
            .toPromise().then( (url) => {
              file.url = url.toString();
              file.type = file.file.type.split('/')[1];
              delete file.file;
            })
        }

        images.push(file);
      }

      data.images = images;

      if(this.data.id) {
        this.materialService.updateMaterial(data, this.data.id)
          .pipe(first())
          .subscribe(
            (data: any) => {
              this.saving = false;

              this.router.navigate(['/account/publications/material/ok'])

              swal(
                'Datos de Materiales',
                'Sus datos de materiales han sido actualizados.',
                'success'
              )
            },
            error => {
              this.saving = false;
              this.ngxToastrService.typeError('Error en la consulta!', 'Se ha producido un error al almacenar los datos del material')
            });
      } else {
        this.materialService.addMaterial(data)
          .pipe(first())
          .subscribe(
            (data: any) => {
              this.saving = false;

              this.router.navigate(['/account/publications/material/ok'])
              swal(
                'Datos de Materiales',
                'Sus datos de materiales han sido almacenados.',
                'success'
              )
            },
            error => {
              this.saving = false;
              this.ngxToastrService.typeError('Error en la consulta!', 'Se ha producido un error al almacenar los datos del material')
            });
      }
    }
  }
}
