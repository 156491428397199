import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class CategoryService {
  constructor(private http: HttpClient) { }

  getCategoriesIndex() {
    return this.http.get(`${environment.urlApi}/category/`);
  }

  getCategories(filter: string) {
      return this.http.get(`${environment.urlApi}/category/?filter=${filter}`);
  }

  getBreadcrumbs(id: string) {
    return this.http.get(`${environment.urlApi}/category/hierarchy/${id}`);
  }

  search(filter: string) {
    return this.http.get(`${environment.urlApi}/category/search?filter[name][regex]=${filter}`);
  }

  getCategorieBySlug(slug: string) {
    return this.http.get(`${environment.urlApi}/category/${slug}`);
  }
}
