import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss']
})
export class RedirectComponent implements OnInit {
  url: string = environment.appUrl;
  constructor(
    private cookieService: CookieService
  ) { }

  ngOnInit() {

    if(environment.appUrl === document.location.host) {
      this.url = `${environment.adminUrl}/admin`;
    }

    setTimeout(() => {
      document.location.href = `https://${this.url}`;
    }, 3000);
    //document.location.href = `https://${this.url}`;
  }

}
