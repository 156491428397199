export class Media{
    name: string;
    url: string;
    type: string;
    platform: string;
    copyright: string;
    footer: string;
    file: any;
    subscribersOnly: boolean;
}
