import { Country, Province, Category, Media, Link, Attributes } from "..";
import { MLItem } from "../mlitem";

export class Manufacturer {
  id: string;
  basic: boolean;
  draft: boolean;
  published: boolean;
  datePublish: string;
  title: MLItem;
  url: string;
  createdBy: string;
  country: Country | string;
  province: Province | string;
  description: MLItem;
  descriptionSubscribers: MLItem;
  distribution: Country[] | string[];
  category: Category[] | string[];
  video: Media | null;
  linkSocialNetworks: Link[];
  dealers: Attributes[];
  logo: Media;
  headerImage: Media;
  documents: Media[];
  createdByTectonica: boolean;
  keywords: string[];
  linkRelatedArticles: Link[];
  linkRelatedConstructionDetails: Link[];
  linkRelatedProjects: Link[];
  linkRelatedMaterials: Link[];
  bookmark: boolean;
  minisiteWoodVisibility: boolean;
}
