import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Material } from 'app/models';
import { PaginatorInterface } from 'app/interfaces/paginator.interface';

@Injectable()
export class MaterialService {

  constructor(
      private http: HttpClient
  ) { }

  findMaterials(infoPaginate: PaginatorInterface) {
    let objFilter = {
      'page': infoPaginate.page.toString(),
      'limit': infoPaginate.limit.toString(),
      'filter[text][regex]': infoPaginate.text || '',
    }
    if(infoPaginate.category) objFilter['filter[category][in]'] = infoPaginate.category;
    if(infoPaginate.msi) objFilter['msi'] = infoPaginate.msi;
    const params = new HttpParams({fromObject: objFilter});

    return this.http.get<any>(`${environment.urlApi}/content/find/material`, { params });
  }

  addMaterial(data: Material) {
    return this.http.post(`${environment.urlApi}/content/material/`, data)
      .pipe(
        map( result => result)
      );
  }

  updateMaterial(data: Material, id: string) {
    return this.http.patch(`${environment.urlApi}/content/material/${id}`, data)
      .pipe(
        map( result => result )
      );
  }

  deleteMaterial(id: string) {
    return this.http.delete(`${environment.urlApi}/content/material/${id}`);
  }

  getFeatured() {
    return this.http.get(`${environment.urlApi}/content/material/featured`);
  }

  getMinisites(filter: string) {
    return this.http.get(`${environment.urlApi}/content/material/minisites?filter=${filter}`);
  }
}
