import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-publications-technical-article-ok',
  templateUrl: './publications-technical-article-ok.component.html',
  styleUrls: ['./publications-technical-article-ok.component.scss']
})
export class PublicationsTechnicalArticleOkComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
