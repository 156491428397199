

import * as fromMainReducer from './reducers/main.reducer';
import * as fromAuthReducer from './reducers/authentication.reducer';
import * as fromArticlesReducer from './reducers/article.reducer';
import * as fromMaterialsReducer from './reducers/material.reducer';
import * as fromDetailsReducer from './reducers/detail.reducer';
import * as fromManufacturerReducer from './reducers/manufacturer.reducer';
import * as fromProjectsReducer from './reducers/project.reducer';
import { ActionReducerMap } from '@ngrx/store';

export interface AppState {
  main: fromMainReducer.MainState;
  auth: fromAuthReducer.AuthState;
  articles: fromArticlesReducer.ArticleState;
  details: fromDetailsReducer.DetailState;
  materials: fromMaterialsReducer.MaterialState;
  manufacturers: fromManufacturerReducer.ManufacturerState;
  projects: fromProjectsReducer.ProjectState;
}

export const appReducers: ActionReducerMap<AppState> = {
  main: fromMainReducer.MainReducer,
  auth: fromAuthReducer.AuthReducer,
  articles: fromArticlesReducer.ArticleReducer,
  details: fromDetailsReducer.DetailReducer,
  materials: fromMaterialsReducer.MaterialReducer,
  manufacturers: fromManufacturerReducer.ManufacturerReducer,
  projects: fromProjectsReducer.ProjectReducer,
}

export const isAuthenticated = (state: AppState) => state.auth.isAuthenticated;
