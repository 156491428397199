import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { PaginatorInterface } from 'app/interfaces/paginator.interface';

@Injectable()
export class ContactService {
  constructor(private http: HttpClient) { }

  getOwnContacts(infoPaginate: PaginatorInterface) {
    const params = new HttpParams({fromObject: {
      'page': infoPaginate.page.toString(),
      'limit': infoPaginate.limit.toString(),
      'filter[text][regexFull]': infoPaginate.text || '',
    }});
    return this.http.get(`${environment.urlApi}/contact/own`, {params});
  }

  getContact(id: string) {
    return this.http.get(`${environment.urlApi}/contact/${id}`);
  }

  addContact(data: any) {
    return this.http.post(`${environment.urlApi}/contact/`, data);
  }

  addMessage(data: any, id: string) {
    return this.http.post(`${environment.urlApi}/contact/${id}/add-message`, data);
  }

  addSuggest(data: any) {
    return this.http.post(`${environment.urlApi}/contact/suggest/`, data);
  }

  sendMessage(data: any) {
    return this.http.post(`${environment.urlApi}/contact/general`, data);
  }
}
