import { Injectable } from "@angular/core";


Injectable()
export class ElementTypeService {
  private video_platforms: any[] = [
    { id: '1', name: 'Youtube'},
    { id: '2', name: 'Vimeo'},
  ]

  private rrss_platforms: any[] = [
    { id: '1', name: 'Facebook'},
    { id: '2', name: 'Twitter'},
    { id: '3', name: 'Instagram'},
    { id: '4', name: 'LinkedIn'},
    { id: '5', name: 'Youtube'}
  ]

  private areas: any[] = [
    { id: '1', name: 'Global'},
    { id: '2', name: 'Europa'},
  ]

  private doc_types: any[] = [
    { id: 'pdf', name: 'PDF'},
    { id: 'jpg', name: 'JPG'},
    { id: 'jpeg', name: 'JPEG'},
    { id: 'png', name: 'PNG'},
    { id: 'gif', name: 'GIF'},
    { id: 'dwg', name: 'DWG'}
  ]

  getVideoPlatforms(): any[] {
    return this.video_platforms;
  }

  getRRSSPlatforms(): any[] {
    return this.rrss_platforms;
  }

  getAreas(): any[] {
    return this.areas;
  }

  getDocType(): any[] {
    return this.doc_types;
  }
}
