import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {Country, Province, User} from '../../../models';
import {ProfessionalActivity} from '../../../models/professionalActivity';
import {UserService} from '../../../services/user.service';
import {CountryService} from '../../../services/country.service';
import {AuthenticationService} from '../../../services/authentication.service';
import {ProvinceService} from '../../../services/province.service';
import {ProfessionalActivitiesService} from '../../../services/professionalActivities.service';
import {NGXToastrService} from '../../../services/ngx.toastr.service';
import {SlugifyPipe} from '../../../pipes/slugify.pipe';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.state';
import {first} from 'rxjs/operators';

@Component({
  selector: 'app-admin-user-create',
  templateUrl: './admin-user-create.component.html',
  styleUrls: ['./admin-user-create.component.scss']
})
export class AdminUserCreateComponent implements OnInit {

  @Output() private sendUser = new EventEmitter();

  personalFormGroup: FormGroup;
  billingFormGroup: FormGroup;
  subscriptionFormGroup: FormGroup;

  user: User = new User();
  subscription: any = {};

  userTypes: any = [];

  showPassword = false;
  isLinear = false;

  countries: Country[] = [];
  provinces: Province[] = [];
  activities: ProfessionalActivity[] = [];

  imgAvatar: any;
  imgFile: File;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private countryService: CountryService,
    private authenticationService: AuthenticationService,
    private provinceService: ProvinceService,
    private professionalActivitiesService: ProfessionalActivitiesService,
    private ngxToastrService: NGXToastrService,
    private slugifyPipe: SlugifyPipe,
    private store: Store<AppState>
  ) {
  }

  ngOnInit() {
    this.getCountries();
    this.getProfessionaActivities();
    this.getUserTypes();

    this.personalFormGroup = this.formBuilder.group({
      firstName: [this.user.firstName, Validators.required],
      lastName: [this.user.lastName, Validators.required],
      phone: [this.user.phone, Validators.required],
      country: [this.user.country, Validators.required],
      province: [this.user.province, Validators.required],
      professionalActivity: [this.user.professionalActivity, Validators.required],
      email: [this.user.email, [Validators.email, Validators.required]],
      plainPassword: ['', Validators.required]
    });

    this.user.billingInformation = {
      dni: '',
      businessName: '',
      phone: this.user.phone,
      country: this.user.country,
      address: {
        address: '',
        city: '',
        postalCode: '',
      },
    };

    this.billingFormGroup = this.formBuilder.group({
      document: [this.user.billingInformation.dni, Validators.required],
      name: [this.user.billingInformation.businessName],
      phone: [this.user.billingInformation.phone, Validators.required],
      country: [this.user.billingInformation.country, Validators.required],
      address: [this.user.billingInformation.address.address, Validators.required],
      city: [this.user.billingInformation.address.city, Validators.required],
      postalCode: [this.user.billingInformation.address.postalCode, Validators.required],
    });

    this.subscriptionFormGroup = this.formBuilder.group({
      userType: ['', Validators.required],
      endDate: ['', Validators.required]
    });
  }

  savePersonalData() {
    if (this.personalFormGroup.valid && this.billingFormGroup.valid && this.subscriptionFormGroup.valid) {
      const data = {
        user: null,
        subscription: null
      };

      const user = this.personalFormGroup.value;

      user.billingInformation = {
        dni: this.billingFormGroup.controls['document'].value,
        businessName: this.billingFormGroup.controls['name'].value,
        phone: this.billingFormGroup.controls['phone'].value,
        country: this.billingFormGroup.controls['country'].value,
        address: {
          address: this.billingFormGroup.controls['address'].value,
          city: this.billingFormGroup.controls['city'].value,
          postalCode: this.billingFormGroup.controls['postalCode'].value,
        },
      };

      data.user = user;
      data.subscription = this.subscriptionFormGroup.value;

      this.sendUser.emit(data);
    } else {
      if (this.personalFormGroup.controls['country'].value !== '' && this.billingFormGroup.controls['country'].value === '') {
        this.billingFormGroup.controls['country'].setValue(this.personalFormGroup.controls['country'].value);
      }
    }
  }

  getCountries() {
    this.countryService.getCountries()
      .pipe(first())
      .subscribe(
        (data: any) => {
          this.countries = data;
        },
        error => {
          this.ngxToastrService.typeError('Error en la consulta!', 'Se ha producido un error al obtener los datos de países')
        });
  }

  getUserTypes() {
    this.userService.getUserTypes()
      .pipe(first())
      .subscribe(
        (data: any) => {
          this.userTypes = data.slice(Math.max(data.length - 3, 1));
        },
        error => {
          this.ngxToastrService.typeError('Error en la consulta!', 'Se ha producido un error al obtener los datos de países')
        });
  }

  countrySelected($event) {
    this.provinces = [];
    this.getProvinces($event.value);
    this.savePersonalData();
  }

  getProvinces(countryId: string) {
    this.provinceService.getProvinces(countryId)
      .pipe(first())
      .subscribe(
        (data: any) => {
          this.provinces = data;
        },
        error => {
          this.ngxToastrService.typeError('Error en la consulta!', 'Se ha producido un error al obtener los datos de provincias')
        });
  }

  getProfessionaActivities() {
    this.professionalActivitiesService.getProfessionalActivities()
      .pipe(first())
      .subscribe(
        (data: any) => {
          this.activities = data;
        },
        error => {
          this.ngxToastrService.typeError(
            'Error en la consulta!',
            'Se ha producido un error al obtener los datos de actividades profesionales')
        });
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }

}
