import {Component, Input, OnInit} from '@angular/core';
import { ManufacturerService } from 'app/services/manufacturer.service';
import { NGXToastrService } from 'app/services/ngx.toastr.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss']
})
export class PartnersComponent implements OnInit {
  @Input() inHome: boolean;

  partners: any[] = [];

  constructor(
    private manufacturerService: ManufacturerService,
    private ngxToastrService: NGXToastrService,
  ) { }

  ngOnInit() {
    this.getCategories();
  }

  getCategories() {
    this.manufacturerService.getMediaPartners()
      .pipe(first())
      .subscribe(
        (data: any) => {
          this.partners = data;
        },
        error => {
          if (!this.inHome) { this.ngxToastrService.typeError('Error en la consulta!', 'Se ha producido un error al obtener los datos de media partners') }
        });
  }

}
