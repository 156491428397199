import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Manufacturer } from 'app/models';
import { PaginatorInterface } from 'app/interfaces/paginator.interface';

@Injectable()
export class ManufacturerService {

  constructor(
      private http: HttpClient
  ) { }

  findManufacturers(infoPaginate: PaginatorInterface) {
    let objFilter = {
      'page': infoPaginate.page.toString(),
      'limit': infoPaginate.limit.toString(),
      'filter[text][regex]': infoPaginate.text || '',
    }
    if(infoPaginate.category) objFilter['filter[category][in]'] = infoPaginate.category;
    if(infoPaginate.msi) objFilter['msi'] = infoPaginate.msi;
    const params = new HttpParams({fromObject: objFilter});
    return this.http.get<any>(`${environment.urlApi}/content/find/manufacturer`, {params: params});
}

  addManufacturer(data: Manufacturer) {
      return this.http.post(`${environment.urlApi}/content/manufacturer/`, data)
        .pipe(
          map( result => result )
        );
  }

  updateManufacturer(data: Manufacturer, id: string) {
    return this.http.patch(`${environment.urlApi}/content/manufacturer/${id}`, data)
      .pipe(
        map( result => result )
      );
  }

  ownManufacturer() {
    return this.http.get(`${environment.urlApi}/content/manufacturer/own`)
      .pipe(
        map( result => result)
      );
  }

  getManufacturerContent(id: string) {
    return this.http.get(`${environment.urlApi}/content/manufacturer/${id}/own`)
      .pipe(
        map( result => result)
      );
  }

  getMediaPartners() {
    return this.http.get(`${environment.urlApi}/content/manufacturer/logos-media-partner`)
      .pipe(
        map( result => result)
      );
  }

  getLogos() {
    return this.http.get(`${environment.urlApi}/content/manufacturer/logos`)
      .pipe(
        map( result => result)
      );
  }
}
