import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, first } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { UserService } from './user.service';
import { Location } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class AuthenticationService {
  public loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.hasToken());
  public collective: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.hasCollective());
  public admin: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  get isAdmin() {
    return this.admin.asObservable();
  }

  get isCollective() {
    return this.collective.asObservable();
  }

  constructor(
    private http: HttpClient,
    private router: Router,
    private userService: UserService,
    private _location: Location,
    private cookieService: CookieService,
  ) {}

  loginByIp(): any {
    return this.http.post<any>(`${environment.urlApi}/users/ip`, {});
  }

  login(username: string, password: string) {
    return this.http.post<any>(`${environment.urlApi}/login`, { username: username, password: password })
      .pipe(map(user => {
        if (user && user.token) {
          //localStorage.setItem('currentUser', JSON.stringify(user));
          this.cookieService.set('loggedin', JSON.stringify(user), 90, '/', environment.appUrl);
          this.loggedIn.next(true);
          this.checkRole(user.email, true);
        } else {
          //this.cookieService.delete('loggedin','/',environment.appUrl);
        }

        return user;
      }));
  }

  checkRole(email: string, isLogin: boolean) {
    this.userService.getUser(email)
      .pipe(first())
      .subscribe(
        (data: any) => {
          /*if(this.cookieService.get('loggedin') != '1') {
            this.cookieService.set('loggedin', '1', 7);
            location.reload();
          }*/
          if(data.roles) {
            if(data.roles.includes('ROLE_ADMIN') || data.roles.includes('ROLE_EDITOR')) {
              this.admin.next(true);

              if(environment.appUrl === document.location.host) {
                this.router.navigate(['/redirecting']);
                return;
              }
            } else {
              this.admin.next(false);
              if(environment.adminUrl === document.location.host) {
                this.router.navigate(['/redirecting']);
                return;
              }
            }
          } else {
            this.admin.next(false);
            if(environment.adminUrl === document.location.host) {
              this.router.navigate(['/redirecting']);
              return;
            }
          }

          if(this.cookieService.check('urlRef') && isLogin){
            let urlRef = this.cookieService.get('urlRef');
            this.cookieService.delete('urlRef', '/');
            this.router.navigate([urlRef]);
            return;
          }else if(isLogin){
            //this._location.back();
            this.router.navigate(['/']);
            return;
          }

        },
        error => {
          //this.cookieService.delete('loggedin','/',environment.appUrl);
          console.log(error);
        });
  }

  checkSubscription(email: string) : any {
    return this.userService.getUser(email)
      .pipe(first())
      .subscribe(
        (data: any) => {
          console.log(data);
          if(data.roles){
            if (data.roles.includes('ROLE_ADMIN') ||
              data.roles.includes('ROLE_EDITOR') ||
              data.roles.includes('ROLE_SUB') ||
              data.roles.includes('ROLE_SUB_STUDENT') ||
              data.roles.includes('ROLE_MANUFACTURER') ||
              data.roles.includes('ROLE_MANUFACTURER_PREMIUM') ||
              data.roles.includes('ROLE_MEDIA_PARTNER')) {
              return true;
            }else{
              this.router.navigate(['/']);
              return false;
            }
          }else{
            this.router.navigate(['/']);
            return;
          }
        },
        error => {
          this.router.navigate(['/']);
          return;
        });
  }

  logout() {
    //localStorage.removeItem('currentUser');
    this.cookieService.delete('loggedin','/',environment.appUrl);
    this.loggedIn.next(false);
    this.admin.next(false);
    this.router.navigate(['/login']);
  }

  private hasToken() : boolean {
    //return !!localStorage.getItem('currentUser');
    let userCookie = null;
    if(this.cookieService.check('loggedin')){
      userCookie = this.cookieService.get('loggedin');
    }
    return !!userCookie;
  }

  private hasCollective() : boolean {
    if(this.cookieService.check('loggedin')){
      let userCookie = this.cookieService.get('loggedin');
      let userInfo = JSON.parse(userCookie);
      if(userInfo.ct){
        return true;
      }else{
        return false;
      }
    }
    return false;
  }

  redirect(url: string) {
    document.location.href = url;
  }
}
