import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import { environment } from '../../environments/environment';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class RedirectGuard implements CanActivate {

  constructor(private router: Router, private cookieService: CookieService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    //let currentUserStorage: any = JSON.parse(localStorage.getItem('currentUser'));
    /*let userCookie = null;
    if(this.cookieService.check('loggedin')){
      userCookie = this.cookieService.get('loggedin');
    }
    let currentUserStorage: any = JSON.parse(userCookie);*/

    //if(!currentUserStorage){
      if(route.data.sectionUrl == 'home') {
        window.location.href = 'https://' + environment.appUrl;
        return false;
      }else if(route.data.sectionUrl == 'articlesHome'){
        window.location.href = 'https://' + environment.appUrl + '/articles';
        return false;
      }else if(route.data.sectionUrl == 'materialsHome'){
        window.location.href = 'https://' + environment.appUrl + '/materials';
        return false;
      }else if(route.data.sectionUrl == 'projectsHome'){
        window.location.href = 'https://' + environment.appUrl + '/projects';
        return false;
      }else if(route.data.sectionUrl == 'manufacturersHome'){
        window.location.href = 'https://' + environment.appUrl + '/manufacturers';
        return false;
      }else if(route.data.sectionUrl == 'detailsHome'){
        window.location.href = 'https://' + environment.appUrl + '/constructive-details';
        return false;
      }else if(route.data.sectionUrl == 'searchHome') {
        window.location.href = 'https://' + environment.appUrl + '/search';
        return false;
      }else if(route.data.sectionUrl == 'advertising') {
        window.location.href = 'https://' + environment.appUrl + '/advertising';
        return false;
      }else if(route.data.sectionUrl == 'about-us') {
        window.location.href = 'https://' + environment.appUrl + '/about-us';
        return false;
      }else if(route.data.sectionUrl == 'contact') {
        window.location.href = 'https://' + environment.appUrl + '/contact';
        return false;
      }else if(route.data.sectionUrl == 'section'){
        window.location.href = 'https://' + environment.appUrl + '/section/' + route.params.section;
        return false;
      }else if(route.data.sectionUrl == 'viewer'){
        window.location.href = 'https://' + environment.appUrl + '/constructive-details/' + route.params.slug + '/viewer';
        return false;
      }else if(route.data.sectionUrl == 'login'){
        window.location.href = 'https://' + environment.appUrl + '/login';
        return false;
      }else{
        window.location.href = 'https://' + environment.appUrl + '/'+ route.data.sectionUrl +'/' + route.params.slug;
        return false;
      }
    /*}else{
      return true;
    }*/

  }
}
