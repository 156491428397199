import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { User } from 'app/models';
import { PaginatorInterface } from 'app/interfaces/paginator.interface';
import {UserContents} from "../models/userContents";

@Injectable()
export class UserService {
    constructor(private http: HttpClient) { }

    findUsers(infoPaginate: PaginatorInterface) {
      let objFilter = {
        'page': infoPaginate.page.toString(),
        'limit': infoPaginate.limit.toString(),
        'filter[text][regex]': infoPaginate.text || ''
      }

      if (infoPaginate.role !== '' && infoPaginate.role !== undefined) {
        objFilter['filter[role][eq]'] = infoPaginate.role;
      }

      if (infoPaginate.collective !== undefined && infoPaginate.collective !== '0') {
        objFilter['filter[collective][eq]'] = infoPaginate.collective;
      }

      if (infoPaginate.status !== undefined && infoPaginate.status !== '0') {
        objFilter['filter[status][eq]'] = infoPaginate.status;
      }

      const params = new HttpParams({fromObject: objFilter});
      return this.http.get<any>(`${environment.urlApi}/users/`, {params: params});
    }

    create(data: any) {
        return this.http.post(`${environment.urlApi}/users/`, data);
    }

    register(data: any) {
        return this.http.post(`${environment.urlApi}/register`, data);
    }

    getConfiguration() {
        return this.http.get(`${environment.urlApi}/configuration`);
    }

    payment(email: string) {
        return this.http.get(`${environment.urlApi}/users/${email}/subscriptions`);
    }

    createPayment(email: string, data: any) {
        return this.http.post(`${environment.urlApi}/users/${email}/subscriptions`, data);
    }

    reSubscribe(email: string, data: any) {
      return this.http.post(`${environment.urlApi}/users/${email}/subscribe`, data);
    }

    getUser(email: string) {
      return this.http.get(`${environment.urlApi}/users/${email}`);
    }

    updateUser(data: User, email: string) {
      return this.http.patch(`${environment.urlApi}/users/${email}`, data);
    }

    updateUserContents (data: UserContents, email: string) {
      return this.http.post(`${environment.urlApi}/users/${email}/change-contents`, data);
    }

    deleteUser(email: string) {
      return this.http.delete(`${environment.urlApi}/users/${email}`);
    }

    getPayments() {
      return this.http.get(`${environment.urlApi}/payment/subscriptions`);
    }

    addComment(data: any, email) {
      return this.http.post(`${environment.urlApi}/users/${email}/add-comment`, data);
    }

    reactivate(email) {
      return this.http.post(`${environment.urlApi}/users/${email}/reactivate`, {});
    }

    getUserTypes() {
      return this.http.get(`${environment.urlApi}/users/types`);
    }

    userApply(email, userType) {
      return this.http.post(`${environment.urlApi}/users/${email}/apply`, {user_type: userType});
    }

    recoveryPassword(data: any) {
      return this.http.post(`${environment.urlApi}/resetting-password-request`, data);
    }

    changePassword(data: any) {
      return this.http.post(`${environment.urlApi}/reset-password`, data);
    }
 }
