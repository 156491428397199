import { Component, OnInit, Input } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Manufacturer } from 'app/models';
import { first } from 'rxjs/operators';
import { ContactService } from 'app/services/contact.service';
import { NGXToastrService } from 'app/services/ngx.toastr.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-header-contact-manufacturer',
  templateUrl: './header-contact-manufacturer.component.html',
  styleUrls: ['./header-contact-manufacturer.component.scss']
})
export class HeaderContactManufacturerComponent implements OnInit {

  @Input() manufacturer: Manufacturer = new Manufacturer();
  contactFormGroup: FormGroup;
  modalRef: NgbModalRef;

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private contactService: ContactService,
    private ngxToastrService: NGXToastrService,
  ) { }

  ngOnInit() {
    this.contactFormGroup = this.formBuilder.group({
      phone: ['', Validators.required],
      email: ['', Validators.required],
      subject: ['', Validators.required],
      message: ['', Validators.required],
    });
  }

  open(content) {
    this.modalRef = this.modalService.open(content);
    this.modalRef.result.then((result) => {
      console.log(result)
    }, (reason) => {});
  }

  closeModal() {
    this.modalRef.close();
  }

  onSubmit(form: FormGroup)
  {
    if(form.valid) {
      let data = {
        ...form.value,
        receiver: this.manufacturer.id,
	      message: {
		      message: form.value.message
	      }
      }
      
      this.contactService.addContact(data)
          .pipe(first())
          .subscribe(
            (data: any) => {
              swal(
                'Contacto con Fabricante',
                'Su mensaje al fabricante se ha enviado con éxito.',
                'success'
              )

              this.closeModal();
            },
            error => {

              this.ngxToastrService.typeError('Error en la consulta!', 'Se ha producido un error al enviar el mensaje.')
            });
    }
  }
}
