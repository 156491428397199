import * as fromAuthActions from '../actions/authentication.actions';

export interface AuthState {
  isAuthenticated: boolean;
  loading: boolean;
  user: any;
  errorMessage: string;
}

export const initialState: AuthState = {
  loading: false,
  isAuthenticated: null,
  user: null,
  errorMessage: null
}


export type AuthActions = fromAuthActions.LoginAction
           | fromAuthActions.LoginSuccessAction
           | fromAuthActions.LoginFailureAction
           | fromAuthActions.LogoutAction;

export function AuthReducer(state = initialState, action: AuthActions): AuthState {
  switch (action.type) {
    case fromAuthActions.LOGIN: {
      return {
        ...state,
        loading: true,
        errorMessage: null
      }
    }
    case fromAuthActions.LOGIN_SUCCESS: {
      return {
        ...state,
        isAuthenticated: true,
        errorMessage: null,
        loading: false,
        user: action.payload
      }
    }
    case fromAuthActions.LOGIN_FAILURE: {
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
        errorMessage: 'Los datos introducidos son erróneos',
        user: null
      }
    }
    case fromAuthActions.LOGOUT: {
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
        errorMessage: null,
        user: null
      }
    }
    default: {
      return state;
    }
  }
}
