import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take, first } from 'rxjs/operators';

import { AuthenticationService } from './authentication.service';
import {UserService} from "./user.service";
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthenticationService, private router: Router, private cookieService: CookieService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.isLoggedIn.pipe(
      take(1),
      map((isLoggedIn: boolean) => {
        if (!isLoggedIn) {
          this.router.navigate(['/login']);
          return false;
        }
        // Subscriber only access
        if(typeof(next.data.subscriberOnly)!=="undefined") {
          let userCookie = null;
          if(this.cookieService.check('loggedin')){
            userCookie = this.cookieService.get('loggedin');
          }
          const user = JSON.parse(userCookie);

          if(this.authService.checkSubscription(user.email)){
            return true;
          }else{
            this.router.navigate(['/']);
            return false;
          }
        }else{
          return true;
        }

      })
    );
  }
}
