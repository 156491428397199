import {Component, Input, Output, OnInit, EventEmitter} from '@angular/core';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit {

  Math = Math;

  @Input() scrollTo: string;
  @Input() page: number;
  @Input() limit: number;
  @Input() items: number;
  @Input() showNumbers: boolean;
  @Output() private changeOnPage = new EventEmitter();
  constructor(
    private scrollToService: ScrollToService
  ) { }

  ngOnInit() {
  }

  previousArticles() {
    this.page--;
    this.changeOnPage.emit(this.page);

    this.scrollToReference();
  }


  nextArticles() {
    this.page++;
    this.changeOnPage.emit(this.page);

    this.scrollToReference();
  }

  toArticles(page: number){
    this.changeOnPage.emit(page)

    this.scrollToReference();
  }

  scrollToReference() {
    if(this.scrollTo) {
      const config: ScrollToConfigOptions = {
        target: this.scrollTo
      };
  
      this.scrollToService.scrollTo(config);
    }
  }

}
