import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { ContentService } from 'app/services/entity.service';
import { first } from 'rxjs/operators';
import { CategoryService } from 'app/services/category.service';
import { NGXToastrService } from 'app/services/ngx.toastr.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

@Component({
  selector: 'app-details-entity-breadcrumbs',
  templateUrl: './details-entity-breadcrumbs.component.html',
  styleUrls: ['./details-entity-breadcrumbs.component.scss']
})
export class DetailsEntityBreadcrumbsComponent implements OnInit, OnChanges {

  @Input() breadcrumb: any[] = [];
  @Input() bookmark: boolean;
  @Input() hideLanguage: boolean = false;
  @Input() id: string;
  @Input() type: string;
  @Output() private changeLanguage = new EventEmitter();

  language: string = "ES";
  breadcrumbs: any[] = [];

  constructor(
    private contentService: ContentService,
    private categoryService: CategoryService,
    private ngxToastrService: NGXToastrService,
    private modalService: NgbModal,
    private router: Router
  ) { }

  ngOnInit() {}

  ngOnChanges() {
    this.breadcrumbs = [];
    if(this.breadcrumb && this.breadcrumb.length > 0) {
      this.getBreadcrumbs(this.breadcrumb[0].id)
    }
  }

  getBreadcrumbs(id) {
    this.categoryService.getBreadcrumbs(id).subscribe(
      (response: any) => {
        if(response.id) {
          this.checkChidrenBreadcrumbs(response)
        }
      },
      errorResponse => {
          this.ngxToastrService.typeError('Error en la consulta!', 'Se ha producido un error al obtener los datos de breadcrumbs')
      });
  }

  checkChidrenBreadcrumbs(data) {
    this.breadcrumbs.push({'id': data.id, 'name': data.name});

    if(data.children) {
      this.checkChidrenBreadcrumbs(data.children[0])
    }
  }

  toggleBookmark() {
    if(this.bookmark) {
      this.contentService.deleteBookmark(this.id)
        .pipe(first())
        .subscribe( result => {
          this.bookmark = !this.bookmark;
        })
    } else {
      
      const data = {
        content: this.id
      }
      this.contentService.addBookmark(data)
        .pipe(first())
        .subscribe (result => {
        this.bookmark = !this.bookmark;
      })
    }
  }

  changedLanguage(lang) {
    this.language = lang;
    this.changeLanguage.emit(this.language);
  }

  changedCategory(id) {
    this.router.navigate(['/search'], { queryParams: { section: id, entity: this.type } })
  }

  openRrssModal(content) {
    this.modalService.open(content).result.then((result) => {}, (reason) => {});
  }

}
