import { Injectable, Inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import {environment} from '../../environments/environment';

import { AuthenticationService } from './authentication.service';

@Injectable()
export class DomainAppGuard implements CanActivate {
  constructor(
    private authService: AuthenticationService,
    private router: Router,
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if(environment.appUrl === document.location.host) {
      return true;
    } else {
      this.router.navigate(['/admin'])
      return false;
    }
  }

}