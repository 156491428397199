import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Project } from 'app/models';
import { PaginatorInterface } from 'app/interfaces/paginator.interface';

@Injectable()
export class ProjectService {

  constructor(
      private http: HttpClient
  ) { }

  findProjects(infoPaginate: PaginatorInterface) {
    let objFilter = {
      'page': infoPaginate.page.toString(),
      'limit': infoPaginate.limit.toString(),
      'filter[text][regex]': infoPaginate.text || '',
    }

    if(infoPaginate.category) objFilter['filter[category][in]'] = infoPaginate.category;
    if(infoPaginate.typology) objFilter['filter[typology][in]'] = infoPaginate.typology;
    if(infoPaginate.msi) objFilter['msi'] = infoPaginate.msi;
    const params = new HttpParams({fromObject: objFilter});

    return this.http.get<any>(`${environment.urlApi}/content/find/project`, { params });
  }

  addProject(data: Project) {
    return this.http.post(`${environment.urlApi}/content/project/`, data)
      .pipe(
        map( result => result)
      );
  }

  updateProject(data: Project, id: string) {
    return this.http.patch(`${environment.urlApi}/content/project/${id}`, data)
      .pipe(
        map( result => result )
      );
  }

  deleteProject(id: string) {
    return this.http.delete(`${environment.urlApi}/content/project/${id}`);
  }

  getFeatured() {
    return this.http.get(`${environment.urlApi}/content/project/featured`);
  }

  getMinisites(filter: string) {
    return this.http.get(`${environment.urlApi}/content/project/minisites?filter=${filter}`);
  }
}
