import {Component, Input, OnInit, ViewChild} from '@angular/core';
import { DragScrollComponent } from 'ngx-drag-scroll/lib/ngx-drag-scroll';
import {Article} from '../../../models/content';
import {PaginatorInterface} from '../../../interfaces/paginator.interface';
import {ArticleService} from '../../../services/article.service';
import {ArticleFindInterface} from '../../../interfaces/find.interface';
import { NGXToastrService } from 'app/services/ngx.toastr.service';

@Component({
  selector: 'app-homeschedule',
  templateUrl: './homeschedule.component.html',
  styleUrls: ['./homeschedule.component.scss']
})
export class HomescheduleComponent implements OnInit {

  @ViewChild('nav', {read: DragScrollComponent}) ds: DragScrollComponent;
  @Input() inHome: boolean;
  leftNavDisabled = false;
  rightNavDisabled = false;

  defaultLimit = 12;
  calendarDate: string;
  calendars: Article[];
  infoPaginate: PaginatorInterface = {
        hasNext: false,
        items: 0,
        limit: this.defaultLimit,
        page: 0,
        category: '',
        text: ''
    };

  constructor(
    private articleService: ArticleService,
    private ngxToastrService: NGXToastrService,
  ) { }

  ngOnInit() {
    this.findArticlesBySubtypeCalendar(1, this.dateFormat(new Date(new Date().getFullYear(), new Date().getMonth(), 1)));
  }

  dateFormat(date, separator = '/') {
    function twoDigit(n) { return (n < 10 ? '0' : '') + n; }

    return `${date.getFullYear()}${separator}${twoDigit(date.getMonth() + 1)}${separator}${twoDigit(date.getDate())}`;
  }

  sendResponse($event) {
    this.findArticlesBySubtypeCalendar(1, $event.value.format('YYYY/MM/DD'));
  }

  findArticlesBySubtypeCalendar(page: number, calendarDate: string) {
      this.infoPaginate.page = page;
      this.infoPaginate.calendarDate = calendarDate;

      this.articleService.findArticlesBySubtype(this.infoPaginate, 'calendar').subscribe(
        (response: ArticleFindInterface) => {
          this.calendars = response.data;
          this.infoPaginate = response.info;
        },
        errorResponse => {
          if (!this.inHome) { this.ngxToastrService.typeError('Error en la consulta!', 'Se ha producido un error al obtener los datos de artículos de agenda') }
        });
  }

  moveLeft() {
    this.ds.moveLeft();
  }

  moveRight() {
    this.ds.moveRight();
  }

  leftBoundStat(reachesLeftBound: boolean) {
    this.leftNavDisabled = reachesLeftBound;
  }

  rightBoundStat(reachesRightBound: boolean) {
    this.rightNavDisabled = reachesRightBound;
  }

}
