import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-public-profile-ok',
  templateUrl: './public-profile-ok.component.html',
  styleUrls: ['./public-profile-ok.component.scss']
})
export class PublicProfileOkComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
