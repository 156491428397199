import * as fromMaterialActions from '../actions/material.actions';
import { Material } from 'app/models';

export interface MaterialState {
  category: string;
  search: string;
  page: number;
  materials: Material[];
}

export const initialState: MaterialState = {
  category: '',
  search: '',
  page: 1,
  materials: []
}


export type MaterialActions = fromMaterialActions.MaterialAction;

export function MaterialReducer(state = initialState, action: MaterialActions): MaterialState {
  switch (action.type) {
    case fromMaterialActions.SEARCH_MATERIAL: {
      return {
        category: action.payload.category ? action.payload.category : '',
        search: action.payload.search ? action.payload.search : '',
        page: action.payload.page,
        materials: action.payload.materials
      }
    }
    default: {
      return state;
    }
  }
}
