import {Category} from '../category';
import {MLItem} from '../mlitem';
import {Media} from '../media';
import { Link } from '../link';
import { Attributes } from '..';
import {Minisite} from "../minisite";

export class Project {
  id: string;
  title: MLItem;
  author: string;
  authorLink: string;
  contributor: string;
  category: Category[];
  slug: string;
  datePublish: string;
  images: Media[];
  createdByTectonica: boolean;
  documents: Media[];
  headerImage: Media;
  location: string;
  year: string;
  collaborators: string;
  description: MLItem;
  descriptionSubscribers: MLItem;
  keywords: string[];
  opened: boolean;
  draft: boolean;
  published: boolean;
  complementaryFields: Attributes[];
  featuredManufacturers: Attributes[];
  constructionCompanies: Attributes[];
  photographer: string;
  photographerLink: string;
  linkConstructionDetail: Link;
  linkRelatedArticles: Link[];
  linkRelatedConstructionDetails: Link[];
  linkRelatedProjects: Link[];
  linkRelatedMaterials: Link[];
  featured: boolean;
  import: boolean;
  typology: string[];
  bookmark: boolean;
  minisites: string[];
}
