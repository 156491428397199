import {Category} from '../category';
import {Attributes} from '../attributes';
import {MLItem} from '../mlitem';
import {Media} from '../media';
import {Link} from '../link';
import { Calendar } from '../calendar';
import {Manufacturer} from './manufacturer';
import {Minisite} from "../minisite";

export class Article {
    id: string;
    title: MLItem;
    description: MLItem;
    descriptionSubscribers: MLItem;
    author: string;
    createdCompany: Manufacturer | string;
    contributor: string;
    slug: string;
    category: Category[];
    datePublish: string;
    calendar: Calendar;
    typeArticle: string;
    returnType: string;
    sequence_images: Media[];
    documents: Media[];
    images: Media[];
    headerImage: Media;
    linkRelatedArticles: Link[];
    linkRelatedConstructionDetails: Link[];
    linkRelatedProjects: Link[];
    linkRelatedMaterials: Link[];
    createdByTectonica: boolean;
    opened: boolean;
    draft: boolean;
    published: boolean;
    bookmark: boolean;
    featured: boolean;
    keywords: string[];
    minisites: string[];
    minisiteWoodVisibility: boolean;
}
