import * as fromMainActions from '../actions/main.actions';

export interface MainState {
  drawerOpen: boolean;
}

export const initialState: MainState = {
  drawerOpen: false
}


export type MainActions = fromMainActions.DrawerAction;

export function MainReducer(state = initialState, action: MainActions): MainState {
  switch (action.type) {
    case fromMainActions.TOGGLE_DRAWER: {
      return {
        drawerOpen: action.payload.drawerOpen
      }
    }
    default: {
      return state;
    }
  }
}
