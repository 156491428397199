import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take, first } from 'rxjs/operators';

import { AuthenticationService } from './authentication.service';
import {UserService} from "./user.service";
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class CollectiveGuard implements CanActivate {
  constructor(private authService: AuthenticationService, private router: Router, private cookieService: CookieService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.isCollective.pipe(
      take(1),
      map((isCollective: boolean) => {
        if (isCollective) {
          this.router.navigate(['/']);
          return false;
        }else{
          return true;
        }
      })
    );
  }
}
