import {Category} from '../category';
import {Attributes} from '../attributes';
import {MLItem} from '../mlitem';
import {Media} from '../media';
import {Link} from '../link';
import {Manufacturer} from './manufacturer';
import {Minisite} from "../minisite";

export class Material {
  id: string;
  title: MLItem;
  description: MLItem;
  descriptionSubscribers: MLItem;
  author: string;
  company: string|Manufacturer;
  slug: string;
  category: Category[];
  datePublish: string;
  documents: Media[];
  headerImage: Media;
  video: Media[];
  dealers: Attributes[];
  images: Media[];
  createdByTectonica: boolean;
  keywords: string[];
  draft: boolean;
  published: boolean;
  linkRelatedArticles: Link[];
  linkRelatedConstructionDetails: Link[];
  linkRelatedProjects: Link[];
  featured: boolean;
  bookmark: boolean;
  manufacturerVisibility: boolean;
  minisites: string[];
}
