import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-charts',
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.scss']
})
export class ChartsComponent implements OnInit {

  @Input() ChartType:string;
  @Input() params:any;
  ChartLabels:string[] = ['', 'Nº de Visitas'];
  ChartData:number[] = [1350, 450];
  ChartColors: any[] = [{ backgroundColor: ["#eeeeee", "#075eff"] }];
  Legend:boolean = false;

  constructor() { }

  ngOnInit() {
  }

   // events
   public chartClicked(e:any):void {

  }
 
  public chartHovered(e:any):void {

  }

}
