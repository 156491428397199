import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {first, debounceTime, switchMap, map} from 'rxjs/operators';
import {FileUploader, FileItem, FileUploaderOptions} from 'ng2-file-upload/ng2-file-upload';

import { CountryService } from 'app/services/country.service';
import { ProvinceService } from 'app/services/province.service';
import { CategoryService } from 'app/services/category.service';
import { ElementTypeService } from 'app/services/elementTypes.service';
import { NGXToastrService } from 'app/services/ngx.toastr.service';

import {
  Country,
  Category,
  Province,
  Link,
  Media,
  Manufacturer,
  Attributes,
  ConstructiveDetail,
  Article,
  Project,
  Material,
  User
} from 'app/models';
import { MLItem } from 'app/models/mlitem';
import { ManufacturerService } from 'app/services/manufacturer.service';
import { MediaService } from 'app/services/media.service';
import { SlugifyPipe } from 'app/pipes/slugify.pipe';
import swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { ContentService } from 'app/services/entity.service';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material';
import {Observable, pipe, Subscription} from 'rxjs';
import { PaginatorInterface } from 'app/interfaces/paginator.interface';
import { ConstructiveDetailService } from 'app/services/constructiveDetail.service';
import { ArticleService } from 'app/services/article.service';
import { ProjectService } from 'app/services/project.service';
import { AuthenticationService } from 'app/services/authentication.service';
import { MaterialService } from 'app/services/material.service';
import {UserService} from '../../../services/user.service';

const maxSize: number = 4 * 1024 * 1024;
const maxTotalSize: number = 20 * 1024 * 1024;

const imgFileUploaderOptionsForUser: FileUploaderOptions = {
  url: '',
  allowedMimeType: ['image/png', 'image/jpg', 'image/jpeg', 'image/gif'],
  isHTML5: true,
  maxFileSize: maxSize
};

const imgFileUploaderOptionsForAdmin: FileUploaderOptions = {
  url: '',
  allowedMimeType: ['image/png', 'image/jpg', 'image/jpeg', 'image/gif'],
  isHTML5: true
};

const documentsUploaderOptionsForUser: FileUploaderOptions = {
  url: '',
  isHTML5: true,
  maxFileSize: maxSize
};

const documentsUploaderOptionsForAdmin: FileUploaderOptions = {
  url: '',
  isHTML5: true
};

@Component({
  selector: 'app-public-profile',
  templateUrl: './public-profile.component.html',
  styleUrls: ['./public-profile.component.scss']
})
export class PublicProfileComponent implements OnInit, OnDestroy {
  saving: boolean = false;
  manufacturerId: string = '';
  data: Manufacturer = new Manufacturer();
  slug: string = '';
  isAdmin: boolean = false;
  isLinear: boolean = false;
  childForm: any;

  headerTitle: string = 'Edita tu perfil público';
  isNew: boolean = false;

  sub: Subscription = new Subscription();
  user: any;
  subscription: any;

  profileFormGroup: FormGroup;
  countries: Country[] = [];
  provinces: Province[] = [];
  categories: Category[] = [];
  rrss: Link[] = [];
  distributors: Attributes[] = [];
  files: Media[] = [];
  video_platforms: any[] = [];
  rrss_platforms: any[] = [];
  areas: any[] = [];
  doc_types: any[] = [];
  urlDocument: string = '';

  uploader: FileUploader;
  uploaderLogo: FileUploader;
  uploaderHeaderImg: FileUploader;

  hasBaseDropZoneOver = false;
  hasAnotherDropZoneOver = false;

  selectedCategories: string[] = [];
  nestedTreeControl: NestedTreeControl<any>;
  nestedDataSource: MatTreeNestedDataSource<any>;

  linkRelatedConstructionDetails: Link[] = [];
  linkRelatedArticles: Link[] = [];
  linkRelatedProjects: Link[] = [];
  linkRelatedMaterials: Link[] = [];
  filteredConstructiveDetails: Observable<ConstructiveDetail>;
  filteredArticles: Observable<Article>;
  filteredProjects: Observable<Project>;
  filteredMaterials: Observable<Material>;

  isBasic: boolean = true;

  constructor(
    private _location: Location,
    private formBuilder: FormBuilder,
    private countryService: CountryService,
    private provinceService: ProvinceService,
    private categoryService: CategoryService,
    private elementTypeService: ElementTypeService,
    private ngxToastrService: NGXToastrService,
    private manufacturerService: ManufacturerService,
    private mediaService: MediaService,
    private slugifyPipe: SlugifyPipe,
    private activatedRoute: ActivatedRoute,
    private contentService: ContentService,
    private router: Router,
    private constructiveDetailService: ConstructiveDetailService,
    private articleService: ArticleService,
    private projectService: ProjectService,
    private authService: AuthenticationService,
    private userService: UserService,
    private materialService: MaterialService
  ) {
    this.nestedTreeControl = new NestedTreeControl<any>(this._getChildren);
    this.nestedDataSource = new MatTreeNestedDataSource();
  }

  hasNestedChild = (_: number, nodeData: any) => nodeData.children;

  private _getChildren = (node: any) => node.children;

  ngOnDestroy() {
   this.sub.unsubscribe();
  }

  ngOnInit() {
    this.sub = this.authService.admin.subscribe(isAdmin => this.isAdmin = isAdmin);

    this.getCountries();
    this.getCategories();
    this.getRRSSPlatforms();
    this.getAreas();
    this.getDocType();
    this.getVideoPlatforms();
    this.getNestedCategories('parents');

    if (this.isAdmin) {
      this.profileFormGroup = this.formBuilder.group({
        name: ['', Validators.required],
        name_en: [''],
        url: ['', Validators.required],
        country: [''],
        province: [''],
        description: ['', Validators.required],
        description_en: [''],
        distribution: [[]],
        keywords: ['', Validators.required],
        sector: [[], Validators.required],
        video_platform: [''],
        video_url: [''],
        privacy: [null, Validators.required],
        linkRelatedConstructionDetails: [''],
        linkRelatedArticles: [''],
        linkRelatedProjects: [''],
        linkRelatedMaterials: [''],
        minisiteWoodVisibility: [false, Validators.required],
      });

      this.uploaderHeaderImg = new FileUploader(imgFileUploaderOptionsForAdmin);
      this.uploaderLogo = new FileUploader(imgFileUploaderOptionsForAdmin);
      this.uploader = new FileUploader(documentsUploaderOptionsForAdmin);
    } else {
      this.profileFormGroup = this.formBuilder.group({
        name: ['', Validators.required],
        name_en: [''],
        url: ['', Validators.required],
        country: [''],
        province: [''],
        description: ['', Validators.required],
        description_en: [''],
        distribution: [[]],
        keywords: ['', Validators.required],
        sector: [[], Validators.required],
        video_platform: [''],
        video_url: [''],
        privacy: [null, Validators.required],
        linkRelatedConstructionDetails: [''],
        linkRelatedArticles: [''],
        linkRelatedProjects: [''],
        linkRelatedMaterials: ['']
      });

      this.uploaderHeaderImg = new FileUploader(imgFileUploaderOptionsForUser);
      this.uploaderLogo = new FileUploader(imgFileUploaderOptionsForUser);
      this.uploader = new FileUploader(documentsUploaderOptionsForUser);
    }

    this.uploader.onWhenAddingFileFailed = (fileItem) => {
      this.ngxToastrService.typeError(`Error al subir el archivo ${fileItem.name}`, 'No se ha podido añadir un archivo porque su tamaño es mayor del permitido.')
    };

    this.slug = this.activatedRoute.snapshot.paramMap.get('slug');

    if(this.slug) {
      this.contentService.getContent(this.slug)
        .pipe(first())
        .subscribe(
          (data: Manufacturer) => {
            this.manufacturerId = data.id;
            this.data = data;

            this.loadData();
        },
        error => {
          this.router.navigate(['/page-not-found'])
          this.ngxToastrService.typeError('Error en la consulta!', 'Se ha producido un error al obtener los datos del contenido')
        });
    } else {
      if (this.isAdmin) {
        this.headerTitle = 'Crear un Fabricante';
        this.isNew = true;
        this.loadAutocompletes();
      } else {
        this.manufacturerService.ownManufacturer()
          .pipe(first())
          .subscribe(
            (data: Manufacturer) => {
              this.manufacturerId = data.id;
              this.data = data;

              this.loadData();
            },
            error => {
              this.ngxToastrService.typeError('Error en la consulta!', 'Se ha producido un error al obtener los datos del contenido')
            });
      }
    }
  }

  changeChildForm(data: any) {
    this.childForm = data;
  }

  back() {
    this._location.back();
  }

  loadData() {
    for(const cat of this.data.category) {
      this.selectedCategories.push((<Category>cat).id);
    }

    let videoPlatform: string = '';
    let videoUrl: string = '';

    if(this.data.video) {
      videoPlatform = this.data.video.platform;
      videoUrl = this.data.video.url;
    }

    let countryId: string = '';
    if(this.data.country) {
      if(typeof this.data.country === 'string') {
        countryId = this.data.country
      } else {
        const country: Country = <Country>this.data.country
        countryId = country.id || '';
      }
    }

    this.getProvinces(countryId)

    let provinceId: string = '';
    if(this.data.province)
    {
      if(typeof this.data.province === 'string') {
        provinceId = this.data.province
      } else {
        const province: Province = <Province>this.data.province
        provinceId = province.id || '';
      }
    }

    let distributionIds: string[] = [];

    if(this.data.distribution.length > 0) {
      if(typeof this.data.distribution[0] === 'string') {
        distributionIds = <string[]>this.data.distribution
      } else {
        const distribution: Country[] = <Country[]>this.data.distribution
        distribution.forEach( (country: Country) => {
          distributionIds.push(country.id)
        })
      }
    }

    let keywords = "";
    if(this.data.keywords) {
      for(const key of this.data.keywords) {
        if(key) keywords += `${key},`;
      }
    }

    if (this.isAdmin) {

      const _minisiteWoodVisibility = this.data.minisiteWoodVisibility ? this.data.minisiteWoodVisibility : false;

      this.profileFormGroup = this.formBuilder.group({
        name: [this.data.title.es, Validators.required],
        name_en: [this.data.title.en],
        url: [this.data.url, Validators.required],
        country: [countryId],
        province: [provinceId],
        description: [this.data.description.es, Validators.required],
        description_en: [this.data.description.en],
        distribution: [distributionIds],
        keywords: [keywords, Validators.required],
        sector: [this.selectedCategories, Validators.required],
        video_platform: [videoPlatform],
        video_url: [videoUrl],
        privacy: [true, Validators.required],
        linkRelatedConstructionDetails: [''],
        linkRelatedArticles: [''],
        linkRelatedProjects: [''],
        linkRelatedMaterials: [''],
        minisiteWoodVisibility: [_minisiteWoodVisibility, Validators.required],
      });

    } else {

      this.profileFormGroup = this.formBuilder.group({
        name: [this.data.title.es, Validators.required],
        name_en: [this.data.title.en],
        url: [this.data.url, Validators.required],
        country: [countryId],
        province: [provinceId],
        description: [this.data.description.es, Validators.required],
        description_en: [this.data.description.en],
        distribution: [distributionIds],
        keywords: [keywords, Validators.required],
        sector: [this.selectedCategories, Validators.required],
        video_platform: [videoPlatform],
        video_url: [videoUrl],
        privacy: [true, Validators.required],
        linkRelatedConstructionDetails: [''],
        linkRelatedArticles: [''],
        linkRelatedProjects: [''],
        linkRelatedMaterials: [''],
      });

    }

    this.rrss = this.data.linkSocialNetworks;
    this.distributors = this.data.dealers;
    this.files = this.data.documents;
    this.linkRelatedConstructionDetails = this.data.linkRelatedConstructionDetails || [];
    this.linkRelatedArticles = this.data.linkRelatedArticles || [];
    this.linkRelatedProjects = this.data.linkRelatedProjects || [];
    this.linkRelatedMaterials = this.data.linkRelatedMaterials || [];

    this.loadAutocompletes();
  }

  loadAutocompletes() {
    let infoPaginateConstructiveDetails: PaginatorInterface = {
      hasNext: false,
      items: 0,
      limit: 200,
      page: 1,
      text: '',
      category: ''
    };

    this.filteredConstructiveDetails = this.profileFormGroup.get('linkRelatedConstructionDetails').valueChanges
      .pipe(
        debounceTime(300),
        switchMap(value => {
          infoPaginateConstructiveDetails.text = value;
          return this.constructiveDetailService.findConstructiveDetails(infoPaginateConstructiveDetails)
        })
      );

    let infoPaginateArticle: PaginatorInterface = {
      hasNext: false,
      items: 0,
      limit: 200,
      page: 1,
      text: '',
      category: ''
    };

    this.filteredArticles = this.profileFormGroup.get('linkRelatedArticles').valueChanges
      .pipe(
        debounceTime(300),
        switchMap(value => {
          infoPaginateArticle.text = value;
          return this.articleService.findArticles(infoPaginateArticle)
        })
      );

    let infoPaginateProject: PaginatorInterface = {
      hasNext: false,
      items: 0,
      limit: 200,
      page: 1,
      text: '',
      category: ''
    };

    this.filteredProjects = this.profileFormGroup.get('linkRelatedProjects').valueChanges
      .pipe(
        debounceTime(300),
        switchMap(value => {
          infoPaginateProject.text = value;
          return this.projectService.findProjects(infoPaginateProject)
        })
      );

    let infoPaginateMaterial: PaginatorInterface = {
      hasNext: false,
      items: 0,
      limit: 200,
      page: 1,
      text: '',
      category: ''
    };

    this.filteredMaterials = this.profileFormGroup.get('linkRelatedMaterials').valueChanges
      .pipe(
        debounceTime(300),
        switchMap(value => {
          infoPaginateMaterial.text = value;
          return this.materialService.findMaterials(infoPaginateMaterial)
        })
      );
  }

  onChangeCategory($event) {
    if($event.checked) {
      this.selectedCategories.push($event.source.value)
    } else {
      this.selectedCategories = this.selectedCategories.filter( value => value !== $event.source.value)
    }

    this.profileFormGroup.controls['sector'].setValue(this.selectedCategories)
  }

  getNestedCategories(filter) {
    this.categoryService.getCategories(filter)
      .pipe(first())
      .subscribe( (data: any) => {
        this.nestedDataSource.data = data;
      },
      error => {
        this.ngxToastrService.typeError('Error en la consulta!', 'Se ha producido un error al obtener los datos de categorías')
      });
  }

  getCountries() {
    this.countryService.getCountries()
      .pipe(first())
      .subscribe(
        (data: any) => {
          this.countries = data;
        },
        error => {
          this.ngxToastrService.typeError('Error en la consulta!', 'Se ha producido un error al obtener los datos de países')
        });
  }

  getProvinces(countryId: string) {
    this.provinceService.getProvinces(countryId)
      .pipe(first())
      .subscribe(
        (data: any) => {
          this.provinces = data;
        },
        error => {
          this.ngxToastrService.typeError('Error en la consulta!', 'Se ha producido un error al obtener los datos de provincias')
        });
  }

  getCategories() {
    this.categoryService.getCategories('')
      .pipe(first())
      .subscribe(
        (data: any) => {
          this.categories = data;
        },
        error => {
          this.ngxToastrService.typeError('Error en la consulta!', 'Se ha producido un error al obtener los datos de categorías')
        });
  }

  getRRSSPlatforms() {
    this.rrss_platforms = this.elementTypeService.getRRSSPlatforms();
  }

  getAreas() {
    this.areas = this.elementTypeService.getAreas();
  }

  getDocType() {
    this.doc_types = this.elementTypeService.getDocType();
  }

  getVideoPlatforms() {
    this.video_platforms = this.elementTypeService.getVideoPlatforms();
  }

  // GET DATA

  countrySelected($event) {
    this.provinces = [];
    this.getProvinces($event.value);
  }

  // DROP ZONE
  addDUrlDocument() {
    let media = new Media();
    media.name = this.urlDocument;
    media.url = this.urlDocument;
    this.files.push(media)
    this.urlDocument = "";
  }

  dropped($files) {
    for (var i = 0; i < $files.length; i++) {
      let media = new Media();
      media.name = $files[i].name;
      media.file = $files[i];
      this.files.push(media)
    }

    this.uploader.clearQueue();
  }

  onFileSelected() {
    this.uploader.queue.forEach(element => {
      let media = new Media();
      media.name = element._file.name;
      media.file = element._file;
      this.files.push(media)
    })

    this.uploader.clearQueue();
  }

  droppedHeaderImg($files) {
    let item: FileItem = this.uploaderHeaderImg.queue[this.uploaderHeaderImg.queue.length - 1]

    if (item) {
      this.uploaderHeaderImg.clearQueue();
      this.uploaderHeaderImg.queue.push(item);
    } else {
      this.ngxToastrService.typeError('Error al subir el archivo', 'El tamaño del archivo es demasiado grande.')
    }
  }

  onFileSelectedHeaderImg() {
    let item: FileItem = this.uploaderHeaderImg.queue[this.uploaderHeaderImg.queue.length - 1]

    if (item) {
      this.uploaderHeaderImg.clearQueue();
      this.uploaderHeaderImg.queue.push(item);
    } else {
      this.ngxToastrService.typeError('Error al subir el archivo', 'El tamaño del archivo es demasiado grande.')
    }
  }

  droppedLogo($files) {
    let item: FileItem = this.uploaderLogo.queue[this.uploaderLogo.queue.length - 1]

    if (item) {
      this.uploaderLogo.clearQueue();
      this.uploaderLogo.queue.push(item);
    } else {
      this.ngxToastrService.typeError('Error al subir el archivo', 'El tamaño del archivo es demasiado grande.')
    }
  }

  onFileSelectedLogo() {
    let item: FileItem = this.uploaderLogo.queue[this.uploaderLogo.queue.length - 1]

    if (item) {
      this.uploaderLogo.clearQueue();
      this.uploaderLogo.queue.push(item);
    } else {
      this.ngxToastrService.typeError('Error al subir el archivo', 'El tamaño del archivo es demasiado grande.')
    }
  }

  removeFile(index) {
    this.files.splice(index, 1);
  }

  fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }

  // DROP ZONE
  // RELATED CONSTRUCTIVE DETAILS
  displayFnRelatedConstructiveDetail(data: ConstructiveDetail) {
    if (data) { return data.title.es; }
  }

  addRelatedConstructiveDetail() {
    if(this.profileFormGroup.get('linkRelatedConstructionDetails').value.id) {
      let data = new Link();
      data.referenceId = this.profileFormGroup.get('linkRelatedConstructionDetails').value.id;
      data.name = this.profileFormGroup.get('linkRelatedConstructionDetails').value.title.es;
      data.slug = this.profileFormGroup.get('linkRelatedConstructionDetails').value.slug;
      data.type = "constructiveDetail";
      data.url = `/constructive-details/${this.profileFormGroup.get('linkRelatedConstructionDetails').value.slug}`;

      this.linkRelatedConstructionDetails.push(data)

      this.profileFormGroup.get('linkRelatedConstructionDetails').setValue('');
    }
  }

  removeRelatedConstructiveDetail(index) {
    this.linkRelatedConstructionDetails.splice(index, 1);
  }
  // RELATED CONSTRUCTIVE DETAILS
  // RELATED ARTICLES
  displayFnRelatedArticle(data: ConstructiveDetail) {
    if (data) { return data.title.es; }
  }

  addRelatedArticle() {
    if(this.profileFormGroup.get('linkRelatedArticles').value.id) {
      let data = new Link();
      data.referenceId = this.profileFormGroup.get('linkRelatedArticles').value.id;
      data.name = this.profileFormGroup.get('linkRelatedArticles').value.title.es;
      data.slug = this.profileFormGroup.get('linkRelatedArticles').value.slug;
      data.type = "article";
      data.url = `/articles/${this.profileFormGroup.get('linkRelatedArticles').value.slug}`;

      this.linkRelatedArticles.push(data)

      this.profileFormGroup.get('linkRelatedArticles').setValue('');
    }
  }

  removeRelatedArticle(index) {
    this.linkRelatedArticles.splice(index, 1);
  }
  // RELATED ARTICLES
  // RELATED PROJECTS
  displayFnRelatedProject(data: Project) {
    if (data) { return data.title.es; }
  }

  addRelatedProject() {
    if(this.profileFormGroup.get('linkRelatedProjects').value.id) {
      let data = new Link();
      data.referenceId = this.profileFormGroup.get('linkRelatedProjects').value.id;
      data.name = this.profileFormGroup.get('linkRelatedProjects').value.title.es;
      data.slug = this.profileFormGroup.get('linkRelatedProjects').value.slug;
      data.type = "project";
      data.url = `/projects/${this.profileFormGroup.get('linkRelatedProjects').value.slug}`;

      this.linkRelatedProjects.push(data)

      this.profileFormGroup.get('linkRelatedProjects').setValue('');
    }
  }

  removeRelatedProject(index) {
    this.linkRelatedProjects.splice(index, 1);
  }
  // RELATED PROJECTS
  // RELATED MATERIALS
  displayFnRelatedMaterial(data: Material) {
    if (data) { return data.title.es; }
  }

  addRelatedMaterial() {
    if(this.profileFormGroup.get('linkRelatedMaterials').value.id) {
      let data = new Link();
      data.referenceId = this.profileFormGroup.get('linkRelatedMaterials').value.id;
      data.name = this.profileFormGroup.get('linkRelatedMaterials').value.title.es;
      data.slug = this.profileFormGroup.get('linkRelatedMaterials').value.slug;
      data.type = "material";
      data.url = `/materials/${this.profileFormGroup.get('linkRelatedMaterials').value.slug}`;

      this.linkRelatedMaterials.push(data)

      this.profileFormGroup.get('linkRelatedMaterials').setValue('');
    }
  }

  removeRelatedMaterial(index) {
    this.linkRelatedMaterials.splice(index, 1);
  }
  // RELATED MATERIALS

  addRrss() {
    this.rrss.push(new Link())
  }

  removeRrss(index) {
    this.rrss.splice(index, 1);
  }

  addDistributor() {
    this.distributors.push(new Attributes())
  }

  removeDistributor(index) {
    this.distributors.splice(index, 1);
  }

  cancel() {
    this._location.back();
  }

  dateFormat(date) {
    function twoDigit(n) { return (n < 10 ? '0' : '') + n; }

    return `${date.getFullYear()}/${twoDigit(date.getMonth() + 1)}/${twoDigit(date.getDate())}`;
  }

  async onSubmit(form: FormGroup) {

    if (this.isAdmin && this.childForm && this.childForm.user && this.childForm.subscription) {
      this.saving = true;
      await this.userService.create(this.childForm.user).toPromise().then(
        (updatedUser: User) => {
          this.user = updatedUser;
        });
      await this.userService.createPayment(this.user.username, this.childForm.subscription).toPromise().then(
        (updatedSub: Subscription) => {
          this.subscription = updatedSub;
        });
    } else if (this.isAdmin && !this.isBasic) {
      this.ngxToastrService.typeError('Error', 'No se ha rellenado el Usuario o la Suscripción');
      return;
    }

    if (form.valid) {
      this.saving = true;

      let data = new Manufacturer();

      data.datePublish = this.dateFormat(new Date());

      let title = new MLItem();
      title.es = form.value.name;
      title.en = form.value.name_en;

      data.title = title;

      data.url = form.value.url;
      data.country = form.value.country;
      data.province = form.value.province;

      let description = new MLItem();
      description.es = form.value.description;
      description.en = form.value.description_en;

      data.description = description;
      data.distribution = form.value.distribution;
      data.category = form.value.sector;
      data.linkRelatedConstructionDetails = this.linkRelatedConstructionDetails;
      data.linkRelatedArticles = this.linkRelatedArticles;
      data.linkRelatedProjects = this.linkRelatedProjects;
      data.linkRelatedMaterials = this.linkRelatedMaterials;

      if(this.isAdmin){
        data.minisiteWoodVisibility = form.value.minisiteWoodVisibility;
      }

      data.keywords = this.profileFormGroup.controls['keywords'].value.split(',');

      let video = new Media();

      video.platform = form.value.video_platform;
      video.url = form.value.video_url;
      data.video = video;

      data.linkSocialNetworks = this.rrss;
      data.dealers = this.distributors;

      if (this.isNew && this.isBasic) {
        data.basic = true;
      } else if (this.isNew) {
        data.createdBy = this.user.id;
      }

      if(this.uploaderLogo.queue.length > 0) {
        let logo = new Media();

        let imageData = new FormData();

        imageData.append('media', this.uploaderLogo.queue[0]._file, this.slugifyPipe.transform(this.uploaderLogo.queue[0]._file.name))
        imageData.append('type', this.uploaderLogo.queue[0]._file.type.split('/')[1]);
        imageData.append('name', this.slugifyPipe.transform(this.uploaderLogo.queue[0]._file.name));

        await this.mediaService.uploadImage(imageData)
          .toPromise().then( (url) => {
            logo.url = url.toString();
            logo.type = this.uploaderLogo.queue[0]._file.type.split('/')[1];
            logo.name = this.slugifyPipe.transform(this.uploaderLogo.queue[0]._file.name);
           })

        data.logo = logo;
      }

      if(this.uploaderHeaderImg.queue.length > 0) {
        let headerImage = new Media();
        let imageData = new FormData();

        imageData.append('media', this.uploaderHeaderImg.queue[0]._file, this.slugifyPipe.transform(this.uploaderHeaderImg.queue[0]._file.name))
        imageData.append('type', this.uploaderHeaderImg.queue[0]._file.type.split('/')[1]);
        imageData.append('name', this.slugifyPipe.transform(this.uploaderHeaderImg.queue[0]._file.name));

        await this.mediaService.uploadImage(imageData)
          .toPromise().then( (url) => {
            headerImage.url = url.toString();
            headerImage.type = this.uploaderHeaderImg.queue[0]._file.type.split('/')[1];
            headerImage.name = this.slugifyPipe.transform(this.uploaderHeaderImg.queue[0]._file.name);
           })

        data.headerImage = headerImage;
      }

      let documents: Media[] = [];

      for( const file of this.files ) {
        if(file.file) {
          let fileData = new FormData();
          fileData.append('media', file.file, this.slugifyPipe.transform(file.file.name))
          if(file.file.name.substr(file.file.name.lastIndexOf('.') + 1).toLowerCase() == 'dwg'){
            fileData.append('type', 'dwg');
          }else{
            fileData.append('type', file.file.type.split('/')[1]);
          }
          fileData.append('name', this.slugifyPipe.transform(file.file.name));

          await this.mediaService.uploadImage(fileData)
            .toPromise().then( (url) => {
              file.url = url.toString();
              delete file.file;
             })
        }

        documents.push(file);

      }

      data.documents = documents

      if(this.manufacturerId === '') {
        this.manufacturerService.addManufacturer(data)
          .pipe(first())
          .subscribe(
            (data: any) => {
              this.saving = false;
              swal(
                'Datos de Fabricante',
                'Sus datos de fabricante han sido almacenados.',
                'success'
              )

              this.router.navigate(['/admin/contents'])
            },
            error => {
              this.saving = false;
              this.ngxToastrService.typeError('Error en la consulta!', 'Se ha producido un error al almacenar los datos del fabricante')
            });
      } else {
        delete data.datePublish;

        this.manufacturerService.updateManufacturer(data, this.manufacturerId)
          .pipe(first())
          .subscribe(
            (data: any) => {
              this.saving = false;

              swal(
                'Datos de Fabricante',
                'Sus datos de fabricante han sido actualizados.',
                'success'
              )

              if(this.slug) {
                this.router.navigate(['/admin/contents/manufacturer/ok'])
              }
            },
            error => {
              this.saving = false;
              this.ngxToastrService.typeError('Error en la consulta!', 'Se ha producido un error al almacenar los datos del fabricante')
            });
      }
    }
  }
}
